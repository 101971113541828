import {Link, Outlet} from 'react-router-dom';

import {
  Button,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from '@mui/material';

import {ContestViewData} from '../../../api/responses/ContestViewResponseTypes';
import {formatDate} from '../../../utils/date-utils';
import SubmissionStatus from '../utils/SubmissionStatus';
import {Color, Problem} from '../../../types';
import {ContestSubmission} from '../../../types/entities/ContestSubmission';
import {contestsPaginationStyle} from '../../../pages/contests/contests-table/contest-table-styles';

type SubmissionsTableProps = {
  isLoading: boolean;
  submissions: ContestSubmission[];
  problems: {[id: number]: Problem} | undefined;
  searchParams: URLSearchParams;
  pagination: {
    setPage: (page: number) => void;
    page: number;
    limit: number;
    total: number;
  };
  filterByProblem: (problemId: number) => void;
  contest: ContestViewData;
  groupContest: string | undefined;
  modalRoute: (
    contestId: number,
    groupContestId: string | undefined,
    submissionId: number,
    searchParams: URLSearchParams
  ) => string;

  filterByUser?: (userId: number) => void;
};

function SubmissionsTable({
  isLoading,
  submissions,
  problems,
  searchParams,
  pagination,
  filterByProblem,
  contest,
  groupContest,
  modalRoute,
  filterByUser,
}: SubmissionsTableProps) {
  const theme = useTheme();

  const skeletonEntriesNo = Math.min(pagination.limit - 1, pagination.total - pagination.page * pagination.limit);

  console.log(skeletonEntriesNo);

  return (
    <>
      {isLoading ? (
        <div className="flex flex-col gap-1 w-full">
          <Skeleton variant="rectangular" height={60} />

          {[...Array(skeletonEntriesNo >= 0 ? skeletonEntriesNo : pagination.limit)].map((_, i) => (
            <Skeleton key={i} variant="rectangular" height={62} />
          ))}
          <Skeleton variant="rectangular" width={180} height={42} className="mt-6 justify-self-end self-end" />
        </div>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: 650,
                '& .MuiTableRow-root:hover': {
                  backgroundColor: theme.palette.card.main,
                },
                '& .MuiTableCell-root': {
                  borderColor: theme.palette.card.light,
                  color: theme.palette.card.contrastText,
                },
                '& .MuiTableCell-head': {
                  backgroundColor: theme.palette.card.light,
                  fontSize: '17px',
                },
                backgroundColor: theme.palette.card.dark,
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Problem</TableCell>
                  {filterByUser && <TableCell>Name</TableCell>}
                  <TableCell>Status</TableCell>
                  <TableCell>Points</TableCell>
                  <TableCell>Correctness</TableCell>
                  <TableCell>Submitted at</TableCell>
                  <TableCell>During contest</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              {!(!submissions.length || !problems) && (
                <>
                  <TableBody>
                    {submissions.map((submission, idx) => (
                      <TableRow key={`contest-sub-${idx}`} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                        <TableCell component="th" scope="row">
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              filterByProblem(submission.problemId);
                            }}
                          >
                            {problems[submission.problemId]?.title}
                          </div>
                        </TableCell>
                        {filterByUser && (
                          <TableCell>
                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                filterByUser(submission.userId);
                              }}
                            >
                              {submission.userFirstName} {submission.userLastName}
                            </div>
                          </TableCell>
                        )}
                        <TableCell>
                          <SubmissionStatus
                            compiled={submission.result.compiled}
                            maxGrade={
                              submission.result.results != null &&
                              submission.result.results.every((result) => result.status === 'PASSED')
                            }
                          />
                        </TableCell>
                        <TableCell>{submission.grade}</TableCell>
                        <TableCell>
                          {submission.result.results != null
                            ? `${(
                                (submission.result.results.filter((result) => result.status === 'PASSED').length /
                                  submission.result.results.length) *
                                100
                              ).toFixed(2)}%`
                            : '-'}
                        </TableCell>
                        <TableCell>{formatDate(submission.date)}</TableCell>
                        <TableCell>
                          {new Date(submission.date).getTime() > new Date(contest.start_date).getTime() &&
                          new Date(submission.date).getTime() < new Date(contest.end_date).getTime()
                            ? 'Yes'
                            : 'No'}
                        </TableCell>
                        <TableCell>
                          <Link
                            to={modalRoute(contest.id, groupContest, submission.id, searchParams)}
                            replace={true}
                            style={{textDecoration: 'none'}}
                          >
                            <Button size="small" variant="contained" color={Color.SECONDARY}>
                              View
                            </Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>
          <Outlet context={{submissions, problems}} />

          {(!submissions.length || !problems) && (
            <div className="text-center text-gray-100">
              <h2>No submissions found 😕</h2>
            </div>
          )}

          <div className={'flex justify-end w-full'}>
            <TablePagination
              sx={contestsPaginationStyle(theme)}
              rowsPerPageOptions={[10]}
              component="div"
              count={pagination.total}
              rowsPerPage={pagination.limit}
              page={pagination.page}
              onPageChange={(_, page) => pagination.setPage(Number(page))}
            />
          </div>
        </>
      )}
    </>
  );
}

export default SubmissionsTable;
