import {useContext, useState, useEffect} from 'react';
import Select from 'react-select';

import makeAnimated from 'react-select/animated';
import {Button, Tooltip, Typography} from '@mui/material';

import AppContext from '../../context/AppContext';
import {sendGetRequest, URLs} from '../../api';
import {dropdownStylesConfig} from '../form/dropdown/Dropdown';
import {Group} from '../../types/entities/Group';
import {UserSettings} from '../../types/entities/UserSettings';
import {ModuleEntry} from '../../types/entities/ModuleType';

type FiltersActiveGroupsSelectProps = {
  filteredGroupsIdsList: Array<number>;
  saveCallback: (partialNewUserSettings: UserSettings) => Promise<boolean>;
  handleHttpError: (errorMessage: string) => void;
  module: ModuleEntry;
};

function FilterActiveGroupsSelect({
  filteredGroupsIdsList,
  saveCallback,
  handleHttpError,
  module,
}: FiltersActiveGroupsSelectProps) {
  const dropdownStyles = dropdownStylesConfig();
  const animatedComponents = makeAnimated();

  const [selectedGroups, setSelectedGroups] = useState<{value: number; label: string}[]>([]);
  const [groups, setGroups] = useState<{value: number; label: string}[]>([]);
  const [isSavedSuccessfully, setIsSavedSuccessfully] = useState<boolean>(false);

  useEffect(() => {
    sendGetRequest(URLs.accessibleUserModuleGroups(module.moduleId, false))
      .then((response) => {
        const responseGroups: Group[] = response.data;
        if (responseGroups.length !== 0) {
          setGroups(responseGroups.map((group) => ({value: group.id, label: group.name})));
        }
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  }, []);

  useEffect(() => {
    setSelectedGroups(groups.filter((group) => (filteredGroupsIdsList || []).includes(group.value)));
  }, [groups]);

  const onSelectedGroupsChange = (options: {value: number; label: string}[]) => {
    setIsSavedSuccessfully(false);
    setSelectedGroups(options);
  };

  return (
    <>
      <div className="flex flex-col justify-items-end items-end space-y-4 pt-4">
        <div className="w-full">
          <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            value={selectedGroups}
            isMulti
            options={groups.filter(
              (group) => !selectedGroups.find((selectedGroup) => selectedGroup.value === group.value)
            )}
            onChange={(options: any) => {
              onSelectedGroupsChange(options);
            }}
            styles={dropdownStyles}
          />
        </div>
        <div className="flex flex-row justify-between items-center w-full">
          <Button
            className="w-60"
            variant="contained"
            color="secondary"
            size="medium"
            onClick={async () =>
              setIsSavedSuccessfully(
                await saveCallback({activeGroups: [...selectedGroups.map((group) => group.value)]})
              )
            }
            disabled={groups.length === 0}
          >
            Save
          </Button>
          <div>{isSavedSuccessfully && <Typography variant="body2">Saved successfully!</Typography>}</div>
        </div>
      </div>
    </>
  );
}

export default FilterActiveGroupsSelect;
