import {useContext, useState} from 'react';

import {PermIdentity, ContactPhone} from '@mui/icons-material';
import {Badge} from '@mui/material';

import {URLs} from '../../../../api';
import {sendPatchRequest} from '../../../../api/API';
import {updateProperty} from '../../../../utils';
import {UpdateAccountRequest} from '../../../../api/Requests';
import {User} from '../../../../types';
import AppContext from '../../../../context/AppContext';
import {Form} from '../../../../components';

type EditProfileFormProps = {
  dispachSnackbarMessage: (message: string) => void;
};

function EditProfileForm({dispachSnackbarMessage}: EditProfileFormProps) {
  const context = useContext(AppContext);
  const user = context.user;

  const [errors, setErrors] = useState<Record<string, any>>({});

  const handleSubmit = (data: UpdateAccountRequest) => {
    if (user != null) {
      sendPatchRequest(`${URLs.updateUser}/${user.id}`, data)
        .then((response) => {
          if (response.data) {
            context.updateUser(
              new User({
                ...user,
                nickName: data.nickName,
                phoneNumber: data.phoneNumber,
                firstName: data.firstName,
                lastName: data.lastName,
              })
            );
            dispachSnackbarMessage('Profile updated successfully');
          } else {
            // TODO: check errors on backend
            setErrors(updateProperty(errors, 'password', 'Introdu parola pentru confirmare'));
          }
        })
        .catch((err) => {
          context.dispatchError(err);
        });
    }
  };

  return (
    <>
      {user != null && (
        <>
          <Form
            button={{
              className: 'mt-25',
              label: 'Update profile',
              onClick: handleSubmit,
            }}
            fullWidth={true}
            inputs={[
              [
                {
                  id: 'firstName',
                  error: errors['firstName'],
                  placeholder: 'First name',
                  rules: {
                    length: [
                      {
                        cond: 'not-empty',
                        message: 'You need to set your first name',
                      },
                    ],
                  },
                  startAdornment: <PermIdentity />,
                  type: 'input',
                  value: user.firstName,
                },
                {
                  id: 'lastName',
                  error: errors['lastName'],
                  placeholder: 'Last name',
                  rules: {
                    length: [
                      {
                        cond: 'not-empty',
                        message: 'You need to set your last name',
                      },
                    ],
                  },
                  startAdornment: <PermIdentity />,
                  type: 'input',
                  value: user.lastName,
                },
              ],
              [
                {
                  id: 'nickName',
                  error: errors['nickName'],
                  placeholder: 'Nickname',
                  rules: {
                    length: [
                      {
                        cond: 'between',
                        low: 6,
                        high: 30,
                        message: 'The nickname needs to have between 6 and 30 characters',
                      },
                    ],
                    pattern: [
                      {
                        message: 'The nickname is invalid',
                        type: 'nickName',
                      },
                    ],
                  },
                  startAdornment: <Badge />,
                  type: 'input',
                  value: user.nickName,
                },
                {
                  id: 'phoneNumber',
                  error: errors['phoneNumber'],
                  placeholder: 'Phone Number',
                  rules: {
                    pattern: [
                      {
                        message: 'The phone number is invalid',
                        type: 'phoneNumber',
                      },
                    ],
                  },
                  startAdornment: <ContactPhone />,
                  type: 'input',
                  value: user.phoneNumber,
                },
              ],
            ]}
          />
        </>
      )}
    </>
  );
}

export default EditProfileForm;
