import {Close, PersonRemove} from '@mui/icons-material';
import {Button, Modal, Typography} from '@mui/material';

type RemoveModuleMemberModalProps = {
  closeCallback: () => void;
  removeModuleMemberCallback: () => void;
  userName: string;
};

function RemoveModuleMemberModal({closeCallback, removeModuleMemberCallback, userName}: RemoveModuleMemberModalProps) {
  return (
    <Modal
      open={true}
      onClose={() => closeCallback()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="top-2/4 left-2/4 w-[40%] h-fit p-3 absolute translate-x-[-50%] translate-y-[-50%] bg-background-default">
        <div className="flex flex-col justify-center text-center items-center gap-1 p-2">
          <Typography variant="h4">
            Remove user <b>{userName}</b>
          </Typography>

          <Typography className="pt-2" align="center" variant="body1">
            You&apos;re about to begin the process of removing an user, this will deny their acces to the module&apos;s
            data. Are you sure you want to continue?
          </Typography>

          <div className="flex flex-row justify-center gap-4 mt-6 w-full">
            <Button
              variant="contained"
              color="warning"
              className="mr-4"
              startIcon={<PersonRemove />}
              onClick={() => removeModuleMemberCallback()}
            >
              Remove
            </Button>

            <Button variant="contained" color="gridSecondary" startIcon={<Close />} onClick={() => closeCallback()}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default RemoveModuleMemberModal;
