import * as React from 'react';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';

import {Data, Order} from './ContestTemplatesTable';
import {EnhancedTableProps, HeadCell} from '../../../utils/tables/EnhancedTableHeader';

const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: 'Contest Name',
  },
  // {
  //   id: 'module',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Module',
  // },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    nonSortable: true,
  },
];

export const ContestTemplateEnhancedTableHead = ({order, orderBy, onRequestSort}: EnhancedTableProps<Data, Order>) => {
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.nonSortable ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className="hidden">{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
