import {useContext, useState} from 'react';

import {Button, IconButton, Typography} from '@mui/material';
import {AutoAwesome, EmojiEvents, Rule, Info, Close} from '@mui/icons-material';

import AppContext from '../../context/AppContext';
import {NewContestProps as Props} from './NewContest.props';
import {Form as FormComponent} from '..';
import {CreateContestRequest} from '../../api/Requests';
import {sendRequest} from '../../api';
import {languageOptions} from '../../types/entities/Language';
import ConfirmationModal from '../modal/ConfirmationModal';

export const Form = (props: Props) => {
  const {dispatchError, user} = useContext(AppContext);

  const [errors] = useState<Record<string, any>>({});

  const [selectedLanguage, setSelectedLanguage] = useState<{value: number; label: string} | null>({
    label: languageOptions.find((language) => language.languageId === props.language_id)?.label || 'All languages',
    value: languageOptions.find((language) => language.languageId === props.language_id)?.languageId || -1,
  });
  const [selectedLanguageIdToBe, setSelectedLanguageIdToBe] = useState<number | null>(null);

  const [isHardDeadline, setIsHardDeadline] = useState<boolean>(props.hard_deadline ?? false); // Not the state per se, but a mirror used to show correct set-timeout value

  const languages = [
    {label: 'All languages', value: -1},
    ...languageOptions.map((language) => ({label: language.label, value: language.languageId})),
  ];

  const handleSubmit = async (data: any) => {
    if (!user?.activeModule?.moduleId) {
      dispatchError({
        errorMessage: 'Something went wrong. Please select a module and then try again.',
      });
      return;
    }

    const request: CreateContestRequest = {
      title: data.title,
      password: data.password ?? '',
      description: data.description,
      prize: data.prize ?? '',
      rules: data.rules ?? '',
      about: data.about ?? '',
      //scoring: data.scoring ?? '' // TODO: Discuss with Emil / Serban / Cristi
      problems: (data.pairs ?? []).map((pair: any) => pair.problem),
      module_id: user.activeModule.moduleId,
      hard_deadline: data.hard_deadline,
      contest_language_id: selectedLanguage?.value || -1,
      // submission_timeout: 10, // (EDUARD): FOR NOW, UNUSED ON BACKEND; MIGHT BE USED IN THE FUTURE, TIMEOUT IS ALWAYS 10 SECONDS
    };

    sendRequest(props.method, props.url, request)
      ?.then((response) => {
        if (response) {
          props.onSuccess();
        }
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.data && err.response.data.error) {
            dispatchError({errorMessage: err.response.data.cause.details[0]?.message});
          } else {
            dispatchError({errorMessage: 'There was an error. Please try again later'});
          }
        }
      });
  };

  return (
    <div className="flex flex-col justify-between px-10 py-4">
      <div className="absolute right-1 px-4">
        <IconButton onClick={props.onClose}>
          <Close color="success" sx={{color: 'white'}} />
        </IconButton>
      </div>
      <Typography align="center" variant="h3">
        {props.isEdit ? `Edit '${props.title}' contest template` : 'Create a new contest'}
      </Typography>
      <FormComponent
        button={{
          className: 'mt-25 mb-10',
          label: 'Submit',
          onClick: handleSubmit,
        }}
        formClass="mt-25"
        fullWidth={true}
        onFirstFieldChange={() => props.dataWasEditedCallback && props.dataWasEditedCallback()}
        inputs={[
          [
            {
              id: 'title',
              error: errors['title'],
              placeholder: 'Contest title*',
              rules: {
                length: [
                  {
                    cond: 'not-empty',
                    message: 'You must include a title',
                  },
                ],
              },
              startAdornment: <AutoAwesome />,
              type: 'input',
              value: props.title,
              xs: true,
            },
            {
              id: 'contest_language',
              error: errors['contest_language'],
              options: languages,
              placeholder: 'Contest language',
              rules: {},
              type: 'unmanaged-dropdown',
              onChangex: (selected) => {
                const newSelectedLanguage = languages.find((language) => language.value == selected.value);
                if (newSelectedLanguage && newSelectedLanguage.value != selectedLanguage?.value) {
                  setSelectedLanguageIdToBe(newSelectedLanguage.value);
                }
              },
              valuex: selectedLanguage,
            },
          ],
          [
            {
              id: 'hard_deadline',
              name: 'hard_deadline',
              className: '',
              error: errors['hard_deadline'],
              rules: {},
              type: 'checkbox',
              subTitle: "Caution: A hard-deadline contest can't be accesesd after the end date (useful for exams)",
              value: props.hard_deadline ?? false,
              title: 'Hard Deadline',
              checkboxText: 'Hard Deadline Contest',
              checkboxChangeCallback: (checked) => setIsHardDeadline(checked),
            },
            {
              type: 'info-text',
              id: 'submission_timeout',
              name: 'submission_timeout',
              title: 'Submission Timeout',
              subTitle:
                'After submitting, participants must wait for the specified amount of time before they can submit again.',
              error: errors['submission_timeout'],
              text: `Submission Rate Limiter: 10s`,
              rules: {},
            },
          ],
          [
            {
              id: 'prize',
              className: 'mt-10',
              error: errors['prize'],
              placeholder: 'Prize',
              rules: {},
              startAdornment: <EmojiEvents />,
              type: 'markdown',
              value: props.prize ?? 'Is there any prize involved?',
            },
          ],
          [
            {
              id: 'rules',
              error: errors['rules'],
              placeholder: 'Rules',
              rules: {},
              startAdornment: <Rule />,
              type: 'markdown',
              value: props.rules ?? 'Fill in any specific rules',
            },
          ],
          [
            {
              id: 'about',
              error: errors['about'],
              placeholder: "What's this contest about?",
              rules: {},
              startAdornment: <Info />,
              type: 'input',
              value: props.about,
            },
          ],
          [
            {
              className: 'mt-10',
              id: 'description',
              error: errors['description'],
              placeholder: 'Describe the contest in a few words*',
              rules: {
                length: [
                  {
                    cond: 'not-empty',
                    message: 'You must include a description',
                  },
                ],
              },
              type: 'textarea',
              value: props.description,
            },
          ],
          [
            {
              className: 'mt-25',
              id: 'pairs',
              error: errors['pairs'],
              maxNoEntries: 100,
              rules: {},
              title: 'Problems',
              type: 'problem',
              value: props.pairs,
              selectedLanguageId: selectedLanguage?.value,
              selectedModuleId: (user!.activeModule && user!.activeModule.moduleId) ?? undefined,
              disabled: user!.activeModule!.moduleId === undefined,
              disabledTooltip: "Please first select the contest template's module",
            },
          ],
        ]}
      />
      {selectedLanguageIdToBe && (
        <ConfirmationModal
          message="Changing the contest language will delete all previously assigned problems. Are you sure you want to continue?"
          onClose={() => {
            setSelectedLanguage((prev) => prev);
            setSelectedLanguageIdToBe(null);
          }}
          onAccept={() => {
            setSelectedLanguage({
              label:
                languageOptions.find((language) => language.languageId === selectedLanguageIdToBe)?.label ||
                'All languages',
              value:
                languageOptions.find((language) => language.languageId === selectedLanguageIdToBe)?.languageId || -1,
            });

            setSelectedLanguageIdToBe(null);
          }}
          acceptButton={
            <Button variant="contained" color="warning">
              Accept
            </Button>
          }
        />
      )}
    </div>
  );
};
