import {ReactElement} from 'react';

import {Button, Modal, Typography, useTheme} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

type ConfirmationModalProps = {
  title?: string;
  message: string;
  onClose: () => void;
  onAccept: () => void;
  acceptButton?: ReactElement;
};

function ConfirmationModal({title, message, onClose, onAccept, acceptButton}: ConfirmationModalProps) {
  const theme = useTheme();

  return (
    <>
      <Modal open={true} onClose={onClose}>
        <div className="top-2/4 left-2/4 w-[40%] h-fit p-3 absolute translate-x-[-50%] translate-y-[-50%] bg-background-default select-none">
          <div className="flex flex-col justify-center items-center gap-1 p-2">
            <Typography className="text-center" color={theme.palette.primary.contrastText} variant="h4">
              {title ?? 'Confirm action'}
            </Typography>

            <Typography className="pt-2" align="center" variant="body1">
              {message}
            </Typography>

            <div className="flex flex-row justify-center mt-3 gap-4 items-center w-full">
              <div onClick={() => onAccept()}>
                {acceptButton ? (
                  acceptButton
                ) : (
                  <Button variant="outlined" startIcon={<DeleteIcon />}>
                    Delete
                  </Button>
                )}
              </div>

              <Button
                variant="contained"
                onClick={onClose}
                sx={{
                  '&.MuiButton-root': {
                    marginLeft: '8px',
                    background: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                  },
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ConfirmationModal;
