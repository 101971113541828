import {useContext, useEffect, useState} from 'react';
import {useOutletContext} from 'react-router-dom';

import AppContext from '../../context/AppContext';
import {User} from '../../types';
import {URLs, sendPutRequest} from '../../api';
import {UserSettings} from '../../types/entities/UserSettings';
import {ProfileOutletProps} from './Profile';

function ProfileSettings() {
  const context = useContext(AppContext);

  const {handleHttpError}: ProfileOutletProps = useOutletContext();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onAccountSettingsSave = async (partialNewUserSettings: UserSettings) => {
    const user = context.user;

    return sendPutRequest(URLs.updateUserSettings(context.user!.id), {settings: partialNewUserSettings})
      .then((response) => {
        user!.settings = response.data.settings;
        context.updateUser(new User(user));

        return true;
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
        return false;
      });
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-1">
        <div className="text-white font-bold text-4xl">Settings</div>
        <div className="text-gray-100 text-lg">🎉 Horray! You do not have any account specific settings to set-up!</div>
      </div>
      {/* To be added: Some mechanism to focus (auto-scroll to) specific settings, ( example: #auto-groups )
      to be referenced from other pages.*/}
    </div>
  );
}

export default ProfileSettings;
