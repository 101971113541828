import {useContext, useState} from 'react';

import {Close} from '@mui/icons-material';
import {Modal, IconButton, Button} from '@mui/material';

import {ModuleEntry} from '../../../types/entities/ModuleType';
import AppContext from '../../../context/AppContext';
import {request, URLs} from '../../../api';

type LeaveModuleModalProps = {
  module: ModuleEntry;
  leftModuleCallback: () => void;
  onClose: () => void;
};

function LeaveModuleModal({module, leftModuleCallback, onClose}: LeaveModuleModalProps) {
  const {dispatchError} = useContext(AppContext);

  const [leaveModuleText, setLeaveModuleText] = useState<string>('');

  const unassignFromModule = (moduleId: number) => {
    request<{}, {}, {}>(URLs.moduleLeave(moduleId), {
      method: 'POST',
      successCallback: () => {
        leftModuleCallback();
        onClose();
      },
      errorCallback(error) {
        dispatchError({errorMessage: String(error)});
      },
    });
  };

  return (
    <Modal open={true}>
      <div className="flex flex-col items-center gap-3 bg-background-default top-2/4 left-2/4 w-[50%] h-fit h-max-[85%] px-4 py-5 absolute translate-x-[-50%] translate-y-[-50%] overflow-y-scroll">
        <div className="absolute right-1 px-4">
          <IconButton onClick={() => onClose()}>
            <Close color="success" sx={{color: 'white'}} />
          </IconButton>
        </div>
        <div className="text-4xl text-center mb-6 px-12">
          Managing module <b>{module.moduleName}</b>
        </div>

        <div className="flex flex-col px-4">
          <div className="text-white font-semibold text-2xl mb-1">Leaving the module</div>

          <div className="flex flex-col gap-3">
            <div className="text-gray-100">
              This action will deny all of your access permissions for this module. If you are you sure you want to
              continue, type the name of the module below and procced.
            </div>
            <input
              className="w-full p-[15px] h-14 rounded outline-none text-white border-none bg-inputBox-backgroundColor"
              placeholder={module.moduleName}
              value={leaveModuleText}
              onChange={(event) => setLeaveModuleText(event.target.value)}
            />
            <Button
              className="w-60"
              disabled={leaveModuleText != module.moduleName}
              variant="contained"
              color="warning"
              onClick={() => unassignFromModule(module.moduleId)}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default LeaveModuleModal;
