import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useOutletContext, useParams, useSearchParams} from 'react-router-dom';

import {Button, InputLabel, MenuItem, Select, Switch, Tooltip} from '@mui/material';
import {RotateLeft} from '@mui/icons-material';

import {sendGetRequest, URLs} from '../../../api';
import AppContext from '../../../context/AppContext';
import {Problem, Role, User} from '../../../types';
import {ContestSubmission} from '../../../types/entities/ContestSubmission';
import {ContestContextType} from '../../../pages/contest/Contest';
import SubmissionsTable from './../submissions-table/SubmissionsTable';
import {BASE_URL} from '../../../api/API';
import {ModuleRoleCode} from '../../../types/entities/Role';
import SubmissionsExportButton from './SubmissionsExportButton';
import SubmissionsArchivedExportButton from './SubmissionsArchivedExportButtont';

export const ContestSubmissions = () => {
  const {contest} = useOutletContext<ContestContextType>();
  const {id: contestId, groupContest} = useParams();
  const [submissions, setSubmissions] = useState<Array<ContestSubmission>>([]);
  const [problems, setProblems] = useState<{[id: number]: Problem} | undefined>(undefined);
  const [metadata, setMetadata] = useState<{total: number; page: number; limit: number} | undefined>(undefined);
  const [users, setUsers] = useState<
    Array<{
      memberId: number;
      firstName: string;
      lastName: string;
      email: string;
      role: ModuleRoleCode;
    }>
  >([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);

  const context = useContext(AppContext);

  const {page, limit, problemId, userId} = useMemo(() => {
    const page = Number(searchParams.get('page')) || 0;
    const limit = Number(searchParams.get('limit')) || 10;

    const problemId = Number(searchParams.get('problemId')) || undefined;
    const userId = Number(searchParams.get('userId')) || undefined;

    return {page, limit, problemId, userId};
  }, [searchParams]);

  useEffect(() => {
    if (contest?.group_id) {
      sendGetRequest(URLs.getUsersInGroup(contest.group_id))
        .then((response: any) => {
          setUsers(
            response.data.users.sort((a: User, b: User) => {
              const aFullName = `${a.lastName} ${a.firstName}`;
              const bFullName = `${b.lastName} ${b.firstName}`;

              return aFullName.localeCompare(bFullName);
            })
          );
        })
        .catch((err: any) => {
          // TODO: Error management ;(
          console.error(err);
        });
    }
  }, [contest]);

  useEffect(() => {
    setIsLoading(true);
    sendGetRequest(
      `${URLs.getContestSubmissions(Number(contestId))}?groupContestId=${groupContest}&${searchParams.toString()}`
    ).then((response) => {
      setSubmissions(response.data.submissions);
      setProblems(response.data.problems);
      setMetadata(response.data.metadata);
      setIsLoading(false);
    });
  }, [contestId, groupContest, searchParams]);

  const setPage = useCallback(
    (page: number) => {
      searchParams.set('page', page.toString());
      setSearchParams(searchParams, {replace: true});
    },
    [searchParams, limit]
  );

  const filterByProblem = useCallback(
    (problemId: number) => {
      if (isNaN(problemId)) {
        searchParams.delete('problemId');
      } else {
        searchParams.set('problemId', problemId.toString());
      }

      searchParams.delete('page');
      setSearchParams(searchParams, {replace: true});
    },
    [searchParams]
  );

  const filterByUser = useCallback(
    (userId: number) => {
      if (isNaN(userId)) {
        searchParams.delete('userId');
      } else {
        searchParams.set('userId', userId.toString());
      }

      searchParams.delete('page');
      setSearchParams(searchParams, {replace: true});
    },
    [searchParams]
  );

  const applyLatestSubmissionsFilter = useCallback(() => {
    const latest = searchParams.get('latest') === 'true';
    searchParams.set('latest', (!latest).toString());
    searchParams.delete('page');
    setSearchParams(searchParams, {replace: true});
  }, [searchParams]);

  const applyDuringContestFilter = useCallback(() => {
    const latest = searchParams.get('duringContest') === 'true';
    searchParams.set('duringContest', (!latest).toString());
    searchParams.delete('page');
    setSearchParams(searchParams, {replace: true});
  }, [searchParams]);

  const modalRoute = (
    contestId: number,
    groupContestId: string | undefined,
    submissionId: number,
    searchParams: URLSearchParams
  ) => `/contest/${contestId}/${groupContestId}/submissions/${submissionId}?${searchParams.toString()}`;

  if (!contest) {
    return <div>Contest not found</div>;
  }

  return (
    <div className="flex flex-col items-center h-full">
      <div className="flex flex-col items-center gap-3 max-h-full w-[90%] overflow-scroll">
        <div className="flex justify-between w-full align-middle">
          <div className="flex gap-4 items-center">
            <div className="flex items-center gap-1">
              <InputLabel id="filter-problem-dropdown-label"> Problem </InputLabel>
              <Select
                label="Filter by Problem"
                labelId="filter-problem-dropdown-label"
                id="filter-problem-dropdown"
                value={searchParams.get('problemId') || 'all'}
                onChange={(e) => {
                  filterByProblem(parseInt(e.target.value, 10));
                }}
              >
                <MenuItem value="all"> All </MenuItem>
                {contest.problems.map((problem) => (
                  <MenuItem key={problem.id} value={problem.id}>
                    {problem.title}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className="flex items-center gap-1">
              <InputLabel id="filter-user-dropdown-label"> User </InputLabel>
              <Select
                label="Filter by User"
                labelId="filter-user-dropdown-label"
                id="filter-user-dropdown"
                value={searchParams.get('userId') || 'all'}
                onChange={(e) => {
                  filterByUser(parseInt(e.target.value, 10));
                }}
              >
                <MenuItem value="all"> All </MenuItem>
                {users.map((user) => (
                  <MenuItem key={user.memberId} value={user.memberId}>
                    {user.lastName} {user.firstName}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className="flex items-center gap-1">
              <p style={{marginRight: '-10px'}}> Last Submission </p>
              <Switch
                checked={searchParams.get('latest') === 'true'}
                inputProps={{'aria-label': 'Size switch demo'}}
                onChange={() => applyLatestSubmissionsFilter()}
              />
            </div>
            <div className="flex items-center gap-1">
              <p style={{marginRight: '-10px'}}> During Contest </p>
              <Switch
                checked={searchParams.get('duringContest') === 'true'}
                inputProps={{'aria-label': 'Size switch demo'}}
                onChange={() => applyDuringContestFilter()}
              />
            </div>
            {context.user?.role === Role.ADMIN && (
              <div className="flex items-center gap-1">
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${BASE_URL}${URLs.getMakeContestSubmissions(Number(contestId))}?groupContestId=${groupContest}`
                    );
                  }}
                >
                  Copy Make Link
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    context.user?.token
                      ? navigator.clipboard.writeText('Bearer ' + context.user?.token)
                      : navigator.clipboard.writeText('No valid token');
                  }}
                >
                  Copy Auth Token
                </Button>
              </div>
            )}
          </div>
          <div className="flex flex-row items-center gap-2">
            {context.user &&
              (context.user.role === Role.ADMIN ||
                context.user?.activeModule?.role === ModuleRoleCode.COURSE_ADMIN ||
                context.user?.activeModule?.role === ModuleRoleCode.AUTHOR_TEACHER ||
                context.user?.activeModule?.role === ModuleRoleCode.TEACHER) && (
                <div className="flex items-center gap-1 flex-row">
                  <SubmissionsExportButton
                    groupContest={Number(groupContest)}
                    searchParams={{
                      problemId,
                      userId,
                      total: metadata?.total,
                      latest: searchParams.get('latest') === 'true',
                      duringContest: searchParams.get('duringContest') === 'true',
                    }}
                    contest={contest}
                  />
                  <SubmissionsArchivedExportButton
                    groupContest={Number(groupContest)}
                    searchParams={{
                      problemId,
                      userId,
                      total: metadata?.total,
                      latest: searchParams.get('latest') === 'true',
                      duringContest: searchParams.get('duringContest') === 'true',
                    }}
                    contest={contest}
                  />
                </div>
              )}

            <Tooltip title="Reset filters">
              <Button
                size="small"
                color="secondary"
                onClick={() => {
                  setSearchParams({}, {replace: true});
                }}
              >
                <RotateLeft />
              </Button>
            </Tooltip>
          </div>
        </div>
        {context.user?.activeModule?.role !== ModuleRoleCode.STUDENT && (
          <SubmissionsTable
            isLoading={isLoading}
            submissions={submissions}
            problems={problems}
            searchParams={searchParams}
            modalRoute={modalRoute}
            pagination={{setPage, page, limit, total: metadata?.total ? metadata.total : 0}}
            filterByProblem={filterByProblem}
            contest={contest}
            groupContest={groupContest}
            filterByUser={filterByUser}
          />
        )}
      </div>
    </div>
  );
};
