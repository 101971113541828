import axios, {AxiosError, AxiosRequestHeaders, AxiosResponse} from 'axios';

// TODO: Move it to env var
export const BASE_URL =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://api.devmind.ro/';

const API = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export type URLsType = {
  allContestsInActiveGroups: string;
  availableProblems: (restrctByLanguageId?: number, restrictByModuleId?: number) => string;
  contestLeaderboard: (contestId: number, groupContestId: number, arrangements: string) => string;
  createContest: string;
  createProblem: string;
  createContestProblemSubmission: (problemId: number, groupContestId: number) => string;
  createProblemSubmission: (problemId: number) => string;
  enrollToContestNew: (contestId: number) => string;
  generalLeaderboard: string;
  getContest: string;
  getProblem: (problemId: number) => string;
  getProblemDetailed: (problemId: number) => string;
  getContestProblem: (problemId: number, groupContestId: number) => string;
  getSubmissions: (problemId: number) => string;
  login: string;
  register: string;
  changeSelectedModule: string;
  resetPassword1: string;
  resetPassword2: string;
  verifyAccount: string;
  updateTemplateContest: (templateContestId: number) => string;
  updateProblem: string;
  updateUser: string;
  user: (userId: number) => string;
  activeUsers: (arrangaments: string) => string;
  blockedUsers: (arrangaments: string) => string;

  activeModuleMembers: (moduleId: number, arrangaments: string) => string;
  pendingModuleMembers: (moduleId: number) => string;
  archivedModuleMembers: (moduleId: number, arrangaments: string) => string;

  changeUserStatus: string;
  changeUserPassword: (userId: number) => string;
  getContestSubmissions: (contestId: number) => string;
  getMakeContestSubmissions: (contestId: number) => string;
  getMyContestSubmissions: (contestId: number) => string;
  getUsersInGroup: (groupId: number) => string;
  adminAllGroups: string;
  accessibleUserModuleGroups: (moduleId: number, limitByModuleActiveGroupsSettings?: boolean) => string;
  assignUserToGroup: string;
  unassignUserFromGroup: string;
  createGroup: string;
  groupProgress: (groupId: number) => string;
  availableContests: (moduleId: number, groupId: number) => string;
  assignContestToGroup: string;
  editContestToGroup: string;
  getAllModules: string;
  getMyModules: string;
  getListedModules: string;
  createModule: string;
  updateModule: (moduleId: number) => string;

  userModuleSettings: (userId: number, moduleId: number) => string;

  updateUserSettings: (userId: number) => string;
  updateGroup: (groupId: number) => string;
  deleteGroup: (groupId: number) => string;
  findUsersByEmail: (emailQuery: string) => string;
  findModuleMembersByEmail: (moduleId: number, emailQuery: string) => string;
  paginatedProblems: (filters: string) => string;
  unassignGroupContestFromGroup: string;
  getAllCategories: string;
  deleteCategory: (categoryId: number) => string;
  updateCategory: (categoryId: number) => string;
  createCategory: string;
  importUsers: string;
  getSubjectCoverage: (groupId: number) => string;
  getSuccessRate: (groupId: number, userId: number) => string;
  getSubmissionsActivity: (groupId: number, userId: number) => string;
  eradicateStudent: (studentId: number) => string;
  getContestTemplates: (moduleId: number) => string;
  getContestTemplate: (moduleId: number, templateId: number) => string;
  getProblemAuthors: (problemId: number) => string;
  assignAuthorToProblem: (problemId: number) => string;
  unassignAuthorFromProblem: (problemId: number, memberId: number) => string;
  getContestAuthors: (contestId: number) => string;
  assignAuthorToContest: (contestId: number) => string;
  unassignAuthorFromContest: (contestId: number, memberId: number) => string;
  getAllAuthors: string;
  getAllCourseAdmins: string;
  getAllTeacherAuthors: string;
  getAuthorProblems: (memberId: number) => string;
  getAuthorContests: (memberId: number) => string;

  adminGetUserModuleEntries: (userId: number) => string;
  adminAssignUserToModule: string;
  adminUnassignUserFromModule: (userId: number, moduleId: number) => string;

  assignMemberToModule: (moduleId: number) => string;
  unassignMemberFromModule: (moduleId: number, memberId: number) => string;
  updateModuleMemberStatus: (moduleId: number, memberId: number) => string;
  reviewModuleMemberJoinRequest: (moduleId: number, memberId: number) => string;
  getModuleUsersManagementNotifications: (moduleId: number) => string;
  updateModuleMember: (moduleId: number, memberId: number) => string;

  moduleRequestJoin: (moduleId: number) => string;
  moduleLeave: (moduleId: number) => string;

  getGroupNonMembers: (groupId: number) => string;
  assignMultipleMembersToGroup: (groupId: number) => string;
};

export const URLs: URLsType = {
  allContestsInActiveGroups: '/contests-active-groups',
  availableProblems: (restrctByLanguageId, restrictByModuleId) =>
    `/problems?restrictByLanguageId=${restrctByLanguageId}&restrictByModuleId=${restrictByModuleId}`,
  contestLeaderboard: (contestId: number, groupContestId: number, arrangements: string) =>
    `/contests/${contestId}/rankings?groupContestId=${groupContestId}&${arrangements}`,
  createContest: '/contest-templates',
  createProblem: '/problems',
  createContestProblemSubmission: (problemId: number, groupContestId: number) =>
    `/problems-contest/${problemId}/submit?group_contest_id=${groupContestId}`,
  createProblemSubmission: (problemId: number) => `/problems/${problemId}/submit`,
  enrollToContestNew: (contestId: number) => `/contests/${contestId}/enroll`,
  generalLeaderboard: '/public-ranking',
  getContest: '/contests',
  getProblem: (problemId: number) => `/problems/${problemId}`,
  getProblemDetailed: (problemId: number) => `/problems/details/${problemId}`,
  getContestProblem: (problemId: number, groupContestId: number) => `/contest-problems/${problemId}/${groupContestId}`,
  getSubmissions: (problemId: number) => `/problems/${problemId}/my-submissions`,
  login: '/login',
  register: '/register',
  changeSelectedModule: '/set-selected-module',
  resetPassword1: '/reset-password-1',
  resetPassword2: '/reset-password-2',
  verifyAccount: '/verify',
  updateTemplateContest: (templateContestId: number) => `/contest-templates/${templateContestId}`,
  updateProblem: '/problems',
  updateUser: '/users', // TODO: use /user/:userId
  user: (userId: number) => `/users/${userId}`,
  activeUsers: (arrangaments: string) => `/users/paginated?${arrangaments}&status=active`,
  blockedUsers: (arrangaments: string) => `/users/paginated?${arrangaments}&status=blocked`,

  activeModuleMembers: (moduleId: number, arrangaments: string) =>
    `/modules/${moduleId}/all-members?${arrangaments}&status=active`,
  pendingModuleMembers: (moduleId: number) => `/modules/${moduleId}/all-members/?status=pending`,
  archivedModuleMembers: (moduleId: number, arrangaments: string) =>
    `/modules/${moduleId}/all-members/?${arrangaments}&status=archived`,

  changeUserStatus: '/users/change-status',
  changeUserPassword: (userId: number) => `/users/${userId}/change-password`,
  getContestSubmissions: (contestId: number) => `/contest/${contestId}/submissions`,
  getMakeContestSubmissions: (contestId: number) => `/contest/${contestId}/all-submissions`,
  getMyContestSubmissions: (contestId: number) => `/contest/${contestId}/my-submissions`,
  getUsersInGroup: (groupId: number) => `/groups/${groupId}/users`,
  adminAllGroups: '/groups/all',
  accessibleUserModuleGroups: (moduleId: number, limitByModuleActiveGroupsSettings: boolean = false) =>
    `/my-groups/module/${moduleId}?limitByModuleActiveGroupsSettings=${limitByModuleActiveGroupsSettings}`,
  assignUserToGroup: '/groups/assign-user',
  unassignUserFromGroup: '/groups/unassign-user',
  createGroup: '/groups/create',
  groupProgress: (groupId: number) => `/groups/${groupId}/progress`,
  availableContests: (moduleId: number, groupId: number) => `/contests/available/${moduleId}/${groupId}`,
  assignContestToGroup: '/contests/group-assign',
  editContestToGroup: '/contests/edit-group-assign',
  getAllModules: '/modules/all',
  getMyModules: '/my-modules/all',
  getListedModules: '/modules/listed',
  createModule: '/module',
  updateModule: (moduleId: number) => `/module/${moduleId}`,

  userModuleSettings: (userId: number, moduleId: number) => `/modules/${moduleId}/${userId}/settings`,

  updateUserSettings: (userId: number) => `/users/${userId}/settings`,
  updateGroup: (groupId: number) => `/groups/${groupId}/update`,
  deleteGroup: (groupId: number) => `/groups/${groupId}`,

  findUsersByEmail: (emailQuery: string) => `users/find?email=${emailQuery}`,
  findModuleMembersByEmail: (moduleId: number, emailQuery: string) =>
    `/modules/${moduleId}/find-members?email=${emailQuery}`,

  paginatedProblems: (filters: string) => `problems/paginated?${filters}`,
  unassignGroupContestFromGroup: '/contests/group-unassign',
  getAllCategories: '/category/all',
  deleteCategory: (categoryId: number) => `/category/${categoryId}`,
  updateCategory: (categoryId: number) => `/category/${categoryId}`,
  createCategory: '/category',
  importUsers: '/users/import',
  getSubjectCoverage: (groupId: number) => `/statistics/subject-covered?groupId=${Number(groupId)}`,
  getSuccessRate: (groupId: number, userId: number) =>
    `/statistics/success-rate?groupId=${Number(groupId)}&userId=${Number(userId)}`,
  getSubmissionsActivity: (groupId: number, userId: number) =>
    `/statistics/submissions-activity?groupId=${Number(groupId)}&userId=${Number(userId)}`,
  eradicateStudent: (studentId: number) => `/users/eradicate/${studentId}`,
  getContestTemplates: (moduleId: number) => `/contest-templates/all?module_id=${moduleId}`,
  getContestTemplate: (moduleId: number, templateId: number) =>
    `/contest-templates/${templateId}?module_id=${moduleId}`,
  getProblemAuthors: (problemId: number) => `/authors/problem/${problemId}`,
  assignAuthorToProblem: (problemId: number) => `/authors/problem/${problemId}`,
  unassignAuthorFromProblem: (problemId: number, authorId: number) => `/authors/problem/${problemId}/${authorId}`,
  getContestAuthors: (contestId: number) => `/authors/contest/${contestId}`,
  assignAuthorToContest: (contestId: number) => `/authors/contest/${contestId}`,
  unassignAuthorFromContest: (contestId: number, authorId: number) => `/authors/contest/${contestId}/${authorId}`,
  getAllAuthors: '/authors/all',
  getAllCourseAdmins: '/course-admins/all',
  getAllTeacherAuthors: '/teacher-authors/all',
  getAuthorProblems: (authorId: number) => `/authors/all-problems/${authorId}`,
  getAuthorContests: (authorId: number) => `/authors/all-contests/${authorId}`,

  adminGetUserModuleEntries: (userId: number) => `/modules/user/${userId}`,
  adminAssignUserToModule: '/modules/assign-user',
  adminUnassignUserFromModule: (userId, moduleId) => `/modules/${moduleId}/unassign-user/${userId}`,

  assignMemberToModule: (moduleId: number) => `/modules/${moduleId}/assign-member/`,
  unassignMemberFromModule: (moduleId: number, memberId: number) => `/modules/${moduleId}/unassign-member/${memberId}`,
  updateModuleMemberStatus: (moduleId: number, memberId: number) => `/modules/${moduleId}/update-status/${memberId}`,
  reviewModuleMemberJoinRequest: (moduleId: number, memberId: number) =>
    `/modules/${moduleId}/review-request/${memberId}`,
  getModuleUsersManagementNotifications: (moduleId: number) => `/modules/${moduleId}/dashboard-notifications`,
  updateModuleMember: (moduleId: number, memberId: number) => `/modules/${moduleId}/member/${memberId}`,

  moduleRequestJoin: (moduleId: number) => `/my-modules/request/${moduleId}`,
  moduleLeave: (moduleId: number) => `/my-modules/leave/${moduleId}`,

  getGroupNonMembers: (groupId: number) => `/groups/${groupId}/non-members`,
  assignMultipleMembersToGroup: (groupId: number) => `/groups/${groupId}/assign-multiple-users`,
};

export const setAuthorizationHeader = (token: string) => {
  API.defaults.headers.common['Accept'] = 'json';
  API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const sendGetRequest = (url: string, data?: any) => API.get(url, data);

export const sendPostRequest = (url: string, data?: any) =>
  API.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const sendDeleteRequest = (url: string, data?: any) =>
  API.delete(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });

export const sendPutRequest = (url: string, data: any) => API.put(url, data);
export const sendPatchRequest = (url: string, data: any) => API.patch(url, data);

export const sendRequest = (method: string, url: string, data: any) => {
  switch (method) {
    case 'get':
      return sendGetRequest(url, data);
    case 'post':
      return sendPostRequest(url, data);
    default:
      return sendPutRequest(url, data);
  }
};

export const request = async <
  RequestParamsType extends Record<string, string | number | string>,
  RequestBodyType,
  RequestResponseType,
>(
  API_URL: string,
  details: RequestDetails<RequestParamsType, RequestBodyType, RequestResponseType>
): Promise<void> => {
  let fullURL;
  if (details.params) {
    const keys = Object.keys(details.params);
    // eslint-disable-next-line prefer-const
    fullURL = `${API_URL}?${keys.map((key) => `${key}=${details.params?.[key]}`).join('&')}`;
  }

  try {
    let response: AxiosResponse;
    switch (details.method) {
      case 'GET':
        response = await API.get(fullURL ?? API_URL, {headers: details.headers});
        break;
      case 'POST':
        response = await API.post(fullURL ?? API_URL, details.body, {headers: details.headers});
        break;
      case 'PUT':
        response = await API.put(fullURL ?? API_URL, details.body, {headers: details.headers});
        break;
      case 'DELETE':
        response = await API.delete(fullURL ?? API_URL, {headers: details.headers});
        break;
    }

    details.successCallback(response?.data);
  } catch (error: unknown) {
    details.errorCallback(error as Error | AxiosError);
  }
};

type RequestDetails<ParamsType, BodyType, ResponseType> = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  params?: ParamsType;
  headers?: AxiosRequestHeaders;
  successCallback: (response: ResponseType) => void;
  errorCallback: (error: Error) => void;
} & ({method: 'POST' | 'PUT' | 'DELETE'; body?: BodyType} | {method: 'GET'; body?: never});
