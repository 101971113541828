import React, {useContext, useState} from 'react';

import {Clear} from '@mui/icons-material';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material';

import {theme} from '../../../types/theme/Theme';
import {contestsPaginationStyle} from '../../contests/contests-table/contest-table-styles';
import {ModuleEntry} from '../../../types/entities/ModuleType';
import AppContext from '../../../context/AppContext';
import {fromRoleCodeToModuleString, Role} from '../../../types/entities/Role';

type ProfileModulesTableProps = {
  modules: Array<ModuleEntry>;
  onUnassignRequest: (moduleId: number) => void;
};
const HARDCODED_PUBLIC_MODULE_ID = 9999;

function ProfileModulesTable({modules, onUnassignRequest}: ProfileModulesTableProps) {
  const {user} = useContext(AppContext);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="w-full py-2">
      <TableContainer
        sx={{
          minWidth: 800,
          '& .MuiTableRow-root:hover': {
            backgroundColor: 'transparent',
          },
          '& .MuiTableCell-root': {
            borderColor: theme.palette.card.light,
            color: theme.palette.card.contrastText,
          },
          backgroundColor: theme.palette.secondCard.light,
        }}
        component={Paper}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Module Name</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Status</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {modules.slice(rowsPerPage * page, rowsPerPage * (page + 1)).map((module: ModuleEntry, index) => (
              <TableRow hover key={`${module.moduleName}-${index}`} id={`module-row-${index}`}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{module.moduleName}</TableCell>
                <TableCell>{fromRoleCodeToModuleString(module.role)?.toUpperCase()}</TableCell>
                <TableCell>{module.status}</TableCell>
                <TableCell>
                  <Tooltip title={user?.role === Role.ADMIN ? 'Platform-Admins cannot leave modules' : 'Leave module'}>
                    <span>
                      <IconButton
                        disabled={user?.role === Role.ADMIN}
                        color="error"
                        onClick={() => onUnassignRequest(module.moduleId)}
                      >
                        <Clear />
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={contestsPaginationStyle(theme)}
        rowsPerPageOptions={[5]}
        component="div"
        count={modules.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

export default ProfileModulesTable;
