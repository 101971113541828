import {useState} from 'react';
import Select from 'react-select';

import {Close, DoneAll} from '@mui/icons-material';
import {Alert, Button, Modal, Typography} from '@mui/material';

import {dropdownStylesConfig} from '../../../../components/form/dropdown/Dropdown';
import {ActiveUserRecord} from '../../../../api/responses/PlatformUsersResponseTypes';
import {Group} from '../../../../types/entities/Group';
import {UpdateUserParams} from './types';
import {rolesMapping} from '../../../../utils';
import {Role, RoleCode} from '../../../../types/entities/Role';

type EditActiveUserModalProps = {
  user: ActiveUserRecord;
  allGroups: Array<Group>;
  closeCallback: () => void;
  updateUserCallback: (updatedUser: UpdateUserParams) => void;
};

function EditActiveUserModal({user, allGroups, closeCallback, updateUserCallback}: EditActiveUserModalProps) {
  const [initialActiveUser, _] = useState<UpdateUserParams>({...user});
  const [editActiveUser, setEditActiveUser] = useState<UpdateUserParams>({...user});

  const dropdownStyles = dropdownStylesConfig();

  {
    /* TODO: REFACTOR FORM AFTER WE CHANGE OUR FORM COMPONENT */
  }
  return (
    <Modal
      open={true}
      onClose={() => closeCallback()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="top-2/4 left-2/4 w-[40%] h-fit p-4 absolute translate-x-[-50%] translate-y-[-50%] bg-background-default">
        <div className="flex flex-col justify-center items-center gap-3 p-2">
          <Typography variant="h2">Edit user</Typography>
          <div className="flex flex-col items-start w-full p-4">
            <Typography variant="body1">First Name</Typography>
            <input
              className="w-full p-[15px] h-14 rounded outline-none text-white border-none bg-inputBox-backgroundColor"
              placeholder="First name"
              value={editActiveUser.firstName}
              onChange={(event) => setEditActiveUser({...editActiveUser, firstName: event.target.value})}
            />
            <Typography variant="body1">Last Name</Typography>
            <input
              className="w-full p-[15px] h-14 rounded outline-none text-white border-none bg-inputBox-backgroundColor"
              placeholder="Last name"
              value={editActiveUser.lastName}
              onChange={(event) => setEditActiveUser({...editActiveUser, lastName: event.target.value})}
            />
            <Typography variant="body1">Nickname</Typography>
            <input
              className="w-full p-[15px] h-14 rounded outline-none text-white border-none bg-inputBox-backgroundColor"
              placeholder="Nickname"
              value={editActiveUser.nickName}
              onChange={(event) => setEditActiveUser({...editActiveUser, nickName: event.target.value})}
            />

            <Typography variant="body1">Email</Typography>
            <input
              className="w-full p-[15px] h-14 rounded outline-none text-white border-none bg-inputBox-backgroundColor"
              placeholder="Email"
              value={editActiveUser.email}
              onChange={(event) => setEditActiveUser({...editActiveUser, email: event.target.value})}
            />

            <Typography variant="body1">Role</Typography>
            <Select
              styles={dropdownStyles}
              value={{value: editActiveUser.role, label: rolesMapping(editActiveUser.role).replace(/_/g, ' ')}}
              options={(Object.keys(Role) as Array<keyof typeof Role>).map((key: string) => ({
                value: RoleCode[key as keyof typeof Role],
                label: key.replace(/_/g, ' '),
              }))}
              onChange={(event) => {
                const role: number = (event as {value: number; label: string}).value;
                setEditActiveUser({...editActiveUser, role: role});
              }}
              isSearchable={false}
              menuPlacement="auto"
            />
            <Typography variant="body1">Groups</Typography>
            <Select
              styles={dropdownStyles}
              options={allGroups.map((group) => ({value: group.id, label: group.name}))}
              value={allGroups
                .filter((group) => editActiveUser.groupsIds.includes(group.id))
                .map((group) => ({value: group.id, label: group.name}))}
              onChange={(event) => {
                const groupsIds: Array<number> = (event as Array<{value: number | null; label: string}>).map(
                  (group) => group.value
                ) as Array<number>;
                setEditActiveUser({...editActiveUser, groupsIds: groupsIds});
              }}
              closeMenuOnSelect={false}
              menuPlacement="top"
              isMulti
            />

              <div className="mt-2">

            {initialActiveUser.role !== RoleCode.ADMIN && editActiveUser.role === RoleCode.ADMIN && (
              <Alert severity="warning">
                Giving an user ADMIN rights will assign them to all Modules, mind that if you will undo this action the user will be unassigned from all modules.
              </Alert>
            )}
            {initialActiveUser.role === RoleCode.ADMIN && editActiveUser.role !== RoleCode.ADMIN && (
              <Alert severity="warning">
                Transforming an ADMIN into another type of user account role will unassign them from all the Modules, you will need to manually reassign the user to the desired Modules.
              </Alert>
            )}
            </div>

            <div className="flex flex-row justify-end gap-4 mt-6 w-full">
              <Button
                variant="contained"
                color="primary"
                className="mr-4"
                startIcon={<DoneAll />}
                onClick={() => updateUserCallback(editActiveUser)}
              >
                Save
              </Button>

              <Button variant="contained" color="secondary" startIcon={<Close />} onClick={() => closeCallback()}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EditActiveUserModal;
