import {useContext, useEffect, useMemo, useState} from 'react';
import Select from 'react-select';

import {ModuleEntry} from '../../../types/entities/ModuleType';
import {dropdownStylesConfig} from '../../form/dropdown/Dropdown';
import {request, URLs} from '../../../api';
import AppContext from '../../../context/AppContext';
import {ModuleRoleCode} from '../../../types/entities/Role';

function ModulesSwitchSelect() {
  const {user, dispatchError, changeModuleContext} = useContext(AppContext);

  const dropdownStyles = dropdownStylesConfig();

  const [myModules, setMyModules] = useState<Array<ModuleEntry>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [currentModule, setCurrentModule] = useState<
    | {
        moduleId: number;
        role: ModuleRoleCode;
      }
    | undefined
  >(user?.activeModule);

  useEffect(() => {
    setCurrentModule(user?.activeModule);
  }, [user?.activeModule]);

  useEffect(() => {
    getMyModules();
  }, []);

  const getMyModules = () => {
    setIsLoading(true);
    request<{}, {}, Array<ModuleEntry>>(URLs.getMyModules, {
      method: 'GET',
      successCallback: (response) => {
        setIsLoading(false);
        setMyModules(response);
      },
      errorCallback(error) {
        dispatchError({errorMessage: error.message});
      },
    });
  };

  const options = useMemo(
    () =>
      myModules
        .filter((m) => m.status === 'active')
        .map((m) => ({
          value: m.moduleId,
          label: `${m.moduleName}`,
        })),
    [myModules]
  );

  if (!user) {
    return <></>;
  }

  return (
    <div className="w-96">
      <Select
        isClearable={false}
        isSearchable={false}
        isLoading={isLoading}
        placeholder="Module not selected..."
        styles={dropdownStyles}
        key="context-switch-select"
        onMenuOpen={() => getMyModules()}
        value={
          currentModule
            ? {
                value: currentModule.moduleId,
                label: options.find((m) => m.value == currentModule.moduleId)?.label,
              }
            : null
        }
        options={options}
        onChange={(event) => {
          const selected = event as {value: number; label: string};

          const newModule = myModules.find((m) => m.moduleId == selected.value);

          if (newModule) {
            changeModuleContext(newModule);
          }
        }}
      />
    </div>
  );
}

export default ModulesSwitchSelect;
