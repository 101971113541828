import {useContext} from 'react';

import {GenericMenu} from './generic-menu/GenericMenu';
import {ButtonSize as Size, Color, MenuProps, User} from '../../types';
import ModulesSwitchSelect from './components/ModulesSwitchSelect';
import AppContext from '../../context/AppContext';
import {ModuleRoleCode, Role} from '../../types/entities/Role';
import {defaultPageRedirect} from '../../types/routes/DefaultPageRedirect';

export const roleBasedProps = (user: undefined | User) => {
  if (user) {
    const adminUserTabs = user.role === Role.ADMIN ? [{text: 'Platform', to: '/platform/users'}] : [];

    const studentTabs = user.activeModule?.role === ModuleRoleCode.STUDENT ? [{text: 'Contests', to: '/contests'}] : [];

    const teacherTabs =
      user.activeModule?.role === ModuleRoleCode.TEACHER
        ? [
            {text: 'Contests', to: '/contests'},
            {text: 'Admin', to: '/module-admin/curriculum'},
          ]
        : [];

    const authorTabs =
      user.activeModule?.role === ModuleRoleCode.AUTHOR
        ? [
            {text: 'Problems', to: '/problems'},
            {text: 'Admin', to: '/module-admin/contest-templates'},
          ]
        : [];

    const courseAdmin =
      user.activeModule?.role === ModuleRoleCode.COURSE_ADMIN
        ? [
            {text: 'Contests', to: '/contests'},
            {text: 'Problems', to: '/problems'},
            {text: 'Admin', to: '/module-admin/users'},
          ]
        : [];

    const authorTeacher =
      user.activeModule?.role === ModuleRoleCode.AUTHOR_TEACHER
        ? [
            {text: 'Contests', to: '/contests'},
            {text: 'Problems', to: '/problems'},
            {text: 'Admin', to: '/module-admin/contest-templates'},
          ]
        : [];

    return {
      logo: defaultPageRedirect(user.activeModule?.role ?? null),
      links: [
        {text: 'Profile', to: '/profile'},
        ...studentTabs,
        ...teacherTabs,
        ...authorTabs,
        ...courseAdmin,
        ...authorTeacher,
        ...adminUserTabs,
      ],
      buttons: [],
      components: [<ModulesSwitchSelect key="context-switch-select" />],
    };
  } else {
    return {
      logo: '/',
      links: [
        //{ text: "About", to: "/about" },
        //{ text: "Discover", to: "/discover" },
        {text: 'Contact', to: '/contact'},
      ],
      buttons: [
        {
          color: Color.SECONDARY,
          link: '/login',
          text: 'Log in',
          size: Size.MEDIUM,
        },
        {
          color: Color.PRIMARY,
          link: '/register',
          text: 'Register',
          size: Size.MEDIUM,
        },
      ],
      components: [],
    };
  }
};

/**
 * Horizontal menu with custom options based on the authentication status of the user.
 */
export const Menu = (props: MenuProps) => {
  const context = useContext(AppContext);

  return (
    <>
      <GenericMenu width={props.width} {...roleBasedProps(context.user)} />
    </>
  );
};
