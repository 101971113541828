import {useEffect, useReducer, useState} from 'react';
import {useOutletContext} from 'react-router-dom';

import {
  Button,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import {ModuleData, ModuleType} from '../../../types/entities/ModuleType';
import {request, sendGetRequest, URLs} from '../../../api';
import {ModuleAdminPageOutletProps} from '../../module-dashboard/ModuleAdminPage';
import {theme} from '../../../types/theme/Theme';
import {Color} from '../../../types';

function ModulesManagement() {
  const {handleHttpError}: ModuleAdminPageOutletProps = useOutletContext();
  const [update, forceUpdate] = useReducer((x) => x + 1, 0);

  const [modules, setModules] = useState<Array<ModuleType>>([]);

  const [newModuleName, setNewModuleName] = useState<string>('');
  const [newModuleIsListed, setNewModuleIsListed] = useState<boolean>(false);

  const [message, setMessage] = useState('');

  const [editInfo, setEditInfo] = useState<{id: number; newName: string; isListed: boolean} | null>(null);
  const [editEnabled, setEditEnabled] = useState(false);

  useEffect(() => {
    sendGetRequest(URLs.getAllModules)
      .then((response) => {
        const modules: Array<ModuleType> = response.data;
        setModules(modules);
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  }, [update]);

  const onCreateNewModule = (moduleName: string, isListed: boolean) => {
    request<{}, ModuleData, {}>(URLs.createModule, {
      method: 'POST',
      body: {
        moduleName: moduleName,
        isListed: isListed,
      },
      successCallback: () => {
        setMessage('Module created successfully');
        setNewModuleName('');
        setNewModuleIsListed(false);
        forceUpdate();
      },
      errorCallback: (e) => {
        handleHttpError(e);
      },
    });
  };

  const onUpdateModule = (moduleId: number, {newName, newIsListed}: {newName: string; newIsListed: boolean}) => {
    request<{}, ModuleData, {}>(URLs.updateModule(moduleId), {
      method: 'PUT',
      body: {
        moduleName: newName,
        isListed: newIsListed,
      },
      successCallback: () => {
        setEditEnabled(false);
        forceUpdate();
      },
      errorCallback: (e) => {
        handleHttpError(e);
      },
    });
  };

  return (
    <div className="flex flex-col w-full gap-3">
      <div className="flex items-center w-full justify-between">
        <div className="flex items-center gap-2">
          <input
            className="w-80 p-[15px] h-14 rounded outline-none text-white border-none bg-inputBox-backgroundColor"
            placeholder="New module name..."
            onChange={(event) => {
              setNewModuleName(event.target.value);
            }}
            value={newModuleName}
          />

          <div className="flex flex-row items-center">
            <Checkbox checked={newModuleIsListed} onChange={(event) => setNewModuleIsListed(event.target.checked)} />
            Module is listed
          </div>

          <Button
            disabled={newModuleName === '' || modules.flatMap((m) => m.moduleName).includes(newModuleName)}
            color={Color.SECONDARY}
            variant="contained"
            size="small"
            onClick={() => onCreateNewModule(newModuleName, newModuleIsListed)}
          >
            Create module
          </Button>
        </div>
        {message !== '' && (
          <Typography className="align-middle" color={theme.palette.primary.light} variant="body1">
            {message}
          </Typography>
        )}
      </div>
      <Table
        sx={{
          minWidth: 800,
          '& .MuiTableRow-root:hover': {
            backgroundColor: 'transparent',
          },
          '& .MuiTableCell-root': {
            borderColor: theme.palette.card.light,
            color: theme.palette.card.contrastText,
          },
          backgroundColor: theme.palette.card.dark,
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell> No. </TableCell>
            <TableCell> Name </TableCell>
            <TableCell> Listed </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody style={{borderColor: theme.palette.card.dark}}>
          {modules &&
            modules.map((module, index) => (
              <TableRow key={module.moduleId}>
                <TableCell> {index + 1} </TableCell>
                <TableCell>
                  {editEnabled && editInfo?.id === module.moduleId ? (
                    <div className="flex flex-row items-center gap-2">
                      <input
                        className="w-full p-[15px] h-14 rounded outline-none text-white border-none bg-inputBox-backgroundColor"
                        placeholder="New module's name..."
                        onChange={(event) => {
                          setEditInfo((prev) => {
                            if (prev) {
                              return {...prev, newName: event.target.value};
                            } else {
                              return null;
                            }
                          });
                        }}
                        value={editInfo?.newName}
                      />
                    </div>
                  ) : (
                    module.moduleName
                  )}
                </TableCell>
                <TableCell>
                  {editEnabled && editInfo?.id === module.moduleId ? (
                    <div className="flex flex-row items-center gap-2">
                      <Checkbox
                        checked={editInfo.isListed}
                        onChange={(event) => {
                          setEditInfo((prev) => {
                            if (prev) {
                              return {...prev, isListed: event.target.checked};
                            } else {
                              return null;
                            }
                          });
                        }}
                      />
                    </div>
                  ) : (
                    <Checkbox checked={module.isListed} disabled />
                  )}
                </TableCell>

                <TableCell align="center">
                  {editEnabled && editInfo?.id === module.moduleId ? (
                    <div>
                      <IconButton
                        disabled={editInfo.newName === ''}
                        onClick={() => {
                          onUpdateModule(module.moduleId, {newName: editInfo.newName, newIsListed: editInfo.isListed});
                        }}
                        sx={{color: 'green'}}
                      >
                        <DoneAllIcon />
                      </IconButton>

                      <IconButton
                        onClick={() => {
                          setEditEnabled(false);
                          setEditInfo(null);
                        }}
                        sx={{color: 'white'}}
                      >
                        <ClearIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setEditEnabled(true);
                        setEditInfo({
                          id: module.moduleId,
                          newName: module.moduleName,
                          isListed: module.isListed,
                        });
                      }}
                      sx={{color: 'white'}}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {modules && modules.length === 0 && (
        <div className="flex justify-center py-3 bg-slate-600">
          <Typography variant="h6">No data to display...</Typography>
        </div>
      )}
    </div>
  );
}

export default ModulesManagement;
