export enum Role {
  REGULAR = 'regular',
  ADMIN = 'admin',
}

export enum RoleCode {
  REGULAR = 1,
  ADMIN = 2,
}

export const fromRoleCodeToString = (role: number) => {
  switch (role) {
    case 1:
      return 'regular';
    case 2:
      return 'admin';
    default:
      return null;
  }
};

export enum ModuleRole {
  STUDENT = 'student',
  TEACHER = 'teacher',
  AUTHOR = 'author',
  AUTHOR_TEACHER = 'author-teacher',
  COURSE_ADMIN = 'course-admin',
}

export enum ModuleRoleCode {
  STUDENT = 1,
  TEACHER = 2,
  AUTHOR = 6,
  COURSE_ADMIN = 7,
  AUTHOR_TEACHER = 8,
}

export const fromRoleCodeToModuleString = (role: number) => {
  switch (role) {
    case 1:
      return ModuleRole.STUDENT;
    case 2:
      return ModuleRole.TEACHER;
    case 6:
      return ModuleRole.AUTHOR;
    case 7:
      return ModuleRole.COURSE_ADMIN;
    case 8:
      return ModuleRole.AUTHOR_TEACHER;
    default:
      throw new Error();
  }
};

export const fromRoleStringToModuleCode = (role: string) => {
  switch (role) {
    case 'student':
      return ModuleRoleCode.STUDENT;
    case 'teacher':
      return ModuleRoleCode.TEACHER;
    case 'author':
      return ModuleRoleCode.AUTHOR;
    case 'course-admin':
      return ModuleRoleCode.COURSE_ADMIN;
    case 'author-teacher':
      return ModuleRoleCode.AUTHOR_TEACHER;
    default:
      throw new Error();
  }
};
