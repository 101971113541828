import {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router';

import {CssBaseline, ThemeProvider} from '@mui/material';
import jwtDecode from 'jwt-decode';

import AppContext, {ContextData} from './context/AppContext';
import Router from './routes/Router';
import {theme} from './types/theme/Theme';
import {User} from './types/entities/User';
import ErrorModalDataType from './types/generics/ErrorModalDataType';
import ErrorWrapper from './utils/wrappers/ErrorWrapper';
import {languageOptions} from './types/entities/Language';
import {ModuleEntry} from './types/entities/ModuleType';
import {Menu} from './components';
import {useWidth} from './hooks';
import {request, URLs} from './api';
import {Token} from './types/entities/Token';
import {defaultPageRedirect} from './types/routes/DefaultPageRedirect';

function App() {
  const navigate = useNavigate();

  const [user, setUser] = useState<User | undefined>(User.load(() => navigate('/login')));

  const [errorState, setErrorState] = useState<ErrorModalDataType>({
    isErrorEnabled: false,
    message: '',
    buttonText: 'Go Back',
    hasCloseIconButton: false,
  });

  // TODO: fetch available languages and set the context
  // const [languages, setLanguages] = useState<Record<number, string>>({});

  const loginCallback = (data: any) => {
    const {token} = data;
    const decotedToken = jwtDecode<Token>(token);

    navigate('/profile');
    updateData({
      ...data,
      activeModule: decotedToken.defaultModule
        ? {
            moduleId: decotedToken.defaultModule.moduleId,
            role: decotedToken.defaultModule.role,
          }
        : null,
    });

    const redirectURL = defaultPageRedirect(decotedToken.defaultModule?.role ?? null);
    navigate(redirectURL);
  };

  const updateData = (data: any) => {
    const newUser = new User(data);
    newUser.store();
    setUser(newUser);
  };

  const logoutCallback = () => {
    User.remove();
    setUser(undefined);
    navigate('/login');
  };

  const setModalError = ({
    errorMessage,
    buttonText,
    redirectURL,
    hasCloseIconButton,
  }: {
    errorMessage: string;
    buttonText?: string;
    redirectURL?: string;
    hasCloseIconButton?: boolean;
  }) => {
    setErrorState({isErrorEnabled: true, message: errorMessage, redirectURL, buttonText, hasCloseIconButton});
  };

  const clearError = () => {
    setErrorState({isErrorEnabled: false, message: null});
  };

  const changeModuleContext = (newModuleEntry: ModuleEntry) => {
    request<{}, {newModuleId: number}, {token: string}>(URLs.changeSelectedModule, {
      method: 'POST',
      body: {newModuleId: newModuleEntry.moduleId},
      successCallback: (response) => {
        navigate('/profile');
        updateData({
          ...user,
          token: response.token,
          activeModule: {
            moduleId: newModuleEntry.moduleId,
            role: newModuleEntry.role,
          },
        });
        navigate(defaultPageRedirect(newModuleEntry.role), {replace: true});
      },
      errorCallback: (e: any) => {
        setModalError({
          errorMessage: e.response.data.error ?? 'There was an error. Please try again later.',
          redirectURL: '/profile',
        });
      },
    });
  };

  // useEffect(() => {
  //   // TODO: get languages & set
  //   setLanguages({1: 'Java'})
  // }, []);

  const ref = useRef(null);
  const width = useWidth(ref);

  return (
    <AppContext.Provider
      value={
        new ContextData(
          user,
          loginCallback,
          logoutCallback,
          {
            ...languageOptions.reduce((acc: Record<number, string>, language) => {
              acc[language.languageId] = language.label;
              return acc;
            }, {}),
          },
          errorState,
          setModalError,
          clearError,
          updateData,
          changeModuleContext
        )
      }
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorWrapper>
          <div className="flex flex-col align-middle min-h-full min-w-full m-0 items-center px-16 gap-2" ref={ref}>
            <div className="sticky top-0 w-full z-50">
              <Menu user={user !== undefined ? user : undefined} width={width} />
            </div>
            <Router user={user} />
          </div>
        </ErrorWrapper>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
