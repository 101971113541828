import {useContext, useEffect, useState} from 'react';
import {useOutletContext} from 'react-router-dom';

import {sendGetRequest, sendDeleteRequest, sendPutRequest, URLs} from '../../../api';
import {PutGroupParams} from '../../../api/Requests';
import {Group} from '../../../types/entities/Group';
import {ModuleType} from '../../../types/entities/ModuleType';
import GroupsManagementTable from '../../../components/groups-management/GroupsManagementTable';
import CreateNewGroup from '../../../components/groups-management/CreateNewGroup';
import {ModuleAdminPageOutletProps} from '../ModuleAdminPage';
import AppContext from '../../../context/AppContext';

export type GroupPatchData = {
  name?: string;
};

export const GroupsManagement = () => {
  const {user} = useContext(AppContext);

  const {handleHttpError}: ModuleAdminPageOutletProps = useOutletContext();

  const [groups, setGroups] = useState<Array<Group>>([]);
  const [modules, setModules] = useState<Array<ModuleType>>([]);

  const getGroups = () => {
    sendGetRequest(URLs.accessibleUserModuleGroups(user!.activeModule!.moduleId))
      .then((response) => {
        setGroups(response.data == '' ? [] : response.data);
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    sendGetRequest(URLs.getMyModules)
      .then((response) => {
        const modules: Array<ModuleType> = response.data;
        setModules(modules);
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  }, []);

  const handleModifyGroup = (groupId: number, newData: PutGroupParams) => {
    sendPutRequest(URLs.updateGroup(groupId), newData)
      .then(() => {
        getGroups();
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  const handleGroupDeletion = (groupId: number) => {
    sendDeleteRequest(URLs.deleteGroup(groupId), {})
      .then(() => {
        getGroups();
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  return (
    <div className="flex flex-col gap-4 w-full">
      <CreateNewGroup handleHttpError={handleHttpError} groupAddedDispatch={getGroups} modules={modules} />

      <div>
        <GroupsManagementTable
          groups={groups}
          modules={modules}
          deleteGroup={handleGroupDeletion}
          onModifyGroup={handleModifyGroup}
          handleHttpError={handleHttpError}
        />
      </div>
    </div>
  );
};
