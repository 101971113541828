import {useContext, useEffect, useMemo, useState} from 'react';
import Select from 'react-select';

import {
  Button,
  Checkbox,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import {Close} from '@mui/icons-material';

import {Button as SubmitButton} from '../../../components/button/Button';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import {Group} from '../../../types/entities/Group';
import {fromRoleCodeToModuleString, fromRoleStringToModuleCode, ModuleRole} from '../../../types/entities/Role';
import {theme} from '../../../types/theme/Theme';
import {contestsPaginationStyle} from '../../contests/contests-table/contest-table-styles';
import {dropdownStylesConfig} from '../../../components/form/dropdown/Dropdown';
import {request, URLs} from '../../../api';
import {ModuleGroupNonMembersResult} from '../../../api/responses/GroupsResponseTypes';
import AppContext from '../../../context/AppContext';
import {ButtonSize, Color} from '../../../types';

type AssignUsersToGroupBulkModalProps = {
  selectedGroup: Group;
  onClose: () => void;
};

function AssignUsersToGroupBulkModal({selectedGroup, onClose}: AssignUsersToGroupBulkModalProps) {
  const {user, dispatchError} = useContext(AppContext);

  const dropdownStyles = dropdownStylesConfig();

  const [allUsers, setAllUsers] = useState<Array<ModuleGroupNonMembersResult['users'][0] & {isSelected: boolean}>>([]);

  const [allGroups, setAllGroups] = useState<Array<Group>>([]);

  const [nameQuery, setNameQuery] = useState<string>('');
  const [emailQuery, setEmailQuery] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const [selectedGroupFilter, setSelectedGroupFilter] = useState<number | null>(null);

  const [mainCheckboxState, setMainCheckboxState] = useState<'false' | 'indeterminate' | 'true'>('false');

  const [closeConfirmation, setCloseConfirmation] = useState<boolean>(false);

  const [perPage, setPerPage] = useState<number>(5);
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    request<never, never, ModuleGroupNonMembersResult>(URLs.getGroupNonMembers(selectedGroup.id), {
      method: 'GET',
      successCallback: (response) => {
        setAllUsers(response.users.map((user) => ({...user, isSelected: false})));
      },
      errorCallback: (error: any) => {
        dispatchError({
          errorMessage: error.response.data.error ?? 'There was an error. Please try again later.',
        });
      },
    });

    request<never, never, any>(URLs.accessibleUserModuleGroups(user!.activeModule!.moduleId), {
      method: 'GET',
      successCallback: (response) => {
        setAllGroups(response);
      },
      errorCallback: (error: any) => {
        dispatchError({
          errorMessage: error.response.data.error ?? 'There was an error. Please try again later.',
        });
      },
    });
  }, [selectedGroup]);

  useEffect(() => {
    setPage(0);
  }, [nameQuery, emailQuery, selectedRole, selectedGroupFilter]);

  useEffect(() => {
    if (allUsers.every((u) => u.isSelected)) {
      setMainCheckboxState('true');
    } else if (allUsers.some((u) => u.isSelected)) {
      setMainCheckboxState('indeterminate');
    } else {
      setMainCheckboxState('false');
    }
  }, [allUsers]);

  const submitChanges = (usersEmail: Array<string>) => {
    request<never, {usersEmail: Array<string>}, never>(URLs.assignMultipleMembersToGroup(selectedGroup.id), {
      method: 'POST',
      body: {usersEmail},
      successCallback: () => {
        onClose();
      },
      errorCallback: (error: any) => {
        dispatchError({
          errorMessage: error.response.data.error ?? 'There was an error. Please try again later.',
        });
      },
    });
  };

  const filteredUsers = useMemo(
    () =>
      allUsers
        .filter((user) => {
          if (nameQuery.length > 0) {
            const fullName = `${user.firstName} ${user.lastName}`;
            const nickName = user.nickName;
            return (
              fullName.toLowerCase().includes(nameQuery.toLowerCase()) ||
              nickName.toLowerCase().includes(nameQuery.toLowerCase())
            );
          }
          return true;
        })
        .filter((user) => {
          if (emailQuery.length > 0) {
            return user.email.toLowerCase().includes(emailQuery.toLowerCase());
          }
          return true;
        })
        .filter((user) => {
          if (selectedRole !== null) {
            return user.role == fromRoleStringToModuleCode(selectedRole);
          }
          return true;
        })
        .filter((user) => {
          if (selectedGroupFilter !== null) {
            return user.groupsIds.includes(selectedGroupFilter);
          }
          return true;
        }),
    [nameQuery, emailQuery, selectedRole, selectedGroupFilter, allUsers]
  );

  const groupOptions = useMemo(
    () => [{value: null, label: 'All'}, ...allGroups.map((group: Group) => ({value: group.id, label: group.name}))],
    [allGroups]
  );

  const roleOptions = [
    {value: null, label: 'All'},
    ...(Object.keys(ModuleRole) as Array<keyof typeof ModuleRole>).map((key) => ({
      value: ModuleRole[key],
      label: key.replace(/_/g, ' '),
    })),
  ];

  return (
    <>
      <Modal
        open={true}
        onClose={allUsers.length > 0 && allUsers.some((u) => u.isSelected) ? () => setCloseConfirmation(true) : onClose}
      >
        <div className="flex flex-col gap-5 bg-background-default top-2/4 left-2/4 w-[80%] h-[90%] px-6 py-4 absolute translate-x-[-50%] translate-y-[-50%] overflow-y-scroll">
          <div className="absolute right-1 px-4">
            <IconButton
              onClick={
                allUsers.length > 0 && allUsers.some((u) => u.isSelected) ? () => setCloseConfirmation(true) : onClose
              }
            >
              <Close color="success" sx={{color: 'white'}} />
            </IconButton>
          </div>
          <span className="text-white text-center font-medium text-3xl">
            Assigning New Users to group <span className="font-bold">{selectedGroup.name}</span>
          </span>
          <div className="flex flex-col items-end justify-between">
            <TableContainer className="bg-background-light flex flex-col  h-fit min-h-[540px]">
              <Table
                sx={{
                  '& .MuiTableRow-root:hover': {
                    backgroundColor: 'transparent',
                  },
                  '& .MuiTableCell-root': {
                    borderColor: theme.palette.card.light,
                    color: theme.palette.card.contrastText,
                  },
                  backgroundColor: theme.palette.card.dark,
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Selected</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Groups</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableCell align="center">
                    <Checkbox
                      checked={mainCheckboxState === 'true'}
                      sx={{
                        '&.MuiCheckbox-indeterminate': {
                          color: 'white',
                        },
                      }}
                      indeterminate={mainCheckboxState === 'indeterminate'}
                      onChange={(event) => {
                        const isChecked = event.target.checked;
                        setAllUsers((prev) => {
                          const newUsers = prev.map((u) => {
                            if (filteredUsers.find((fu) => fu.id === u.id)) {
                              return {...u, isSelected: isChecked};
                            }
                            return u;
                          });
                          return newUsers;
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      className="w-full p-[15px] h-14 rounded outline-none text-white border-none bg-inputBox-backgroundColor"
                      placeholder="Search by name or nickname"
                      value={nameQuery as string}
                      onChange={(event) => setNameQuery(event.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      className="w-full p-[15px] h-14 rounded outline-none text-white border-none bg-inputBox-backgroundColor"
                      placeholder="Search email"
                      value={emailQuery as string}
                      onChange={(event) => setEmailQuery(event.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <Select
                      styles={dropdownStyles}
                      options={roleOptions}
                      isSearchable={false}
                      onChange={(event) => {
                        const role = (event as {value: string | null; label: string}).value;
                        setSelectedRole(role);
                      }}
                      menuPlacement="auto"
                    />
                  </TableCell>
                  <TableCell colSpan={2}>
                    <Select
                      styles={dropdownStyles}
                      options={groupOptions}
                      onChange={(event) => {
                        const groupId = (event as {value: number | null; label: string}).value;
                        setSelectedGroupFilter(groupId);
                      }}
                      menuPlacement="auto"
                    />
                  </TableCell>
                  {filteredUsers.slice(page * perPage, page * perPage + perPage).map((member) => (
                    <TableRow key={member.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                      <TableCell align="center">
                        <Checkbox
                          checked={member.isSelected}
                          onChange={(event) => {
                            const isChecked = event.target.checked;

                            setAllUsers((prev) => {
                              const newUsers = prev.map((u) => {
                                if (u.id === member.id) {
                                  return {...u, isSelected: isChecked};
                                }
                                return u;
                              });
                              return newUsers;
                            });
                          }}
                        />
                      </TableCell>

                      <TableCell>
                        <div className="flex flex-col items-start">
                          <span>{`${member.firstName} ${member.lastName}`}</span>
                          <span className="text-gray-400">{`${member.nickName}`}</span>
                        </div>
                      </TableCell>
                      <TableCell>{member.email}</TableCell>
                      <TableCell>{fromRoleCodeToModuleString(member.role)}</TableCell>
                      <TableCell>
                        <div className="flex flex-row flex-wrap w-36 justify-center">
                          {member.groupsIds.length > 0 ? (
                            member.groupsIds.map((groupId: number) => (
                              <div key={groupId} className="bg-card-light px-2 py-1 w-fit rounded-xl text-center mb-1">
                                {allGroups.find((group) => group.id == groupId)?.name}
                              </div>
                            ))
                          ) : (
                            <span className="text-center">No assigned groups</span>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {filteredUsers && filteredUsers.length === 0 && (
                <Typography className="p-5" align="center" variant="h5">
                  No active users found based on the criteria.
                </Typography>
              )}
            </TableContainer>
            <TablePagination
              sx={contestsPaginationStyle(theme)}
              rowsPerPageOptions={[5]}
              component="div"
              count={filteredUsers.length}
              rowsPerPage={perPage}
              page={page}
              onPageChange={(event, page) => setPage(page)}
              onRowsPerPageChange={(event) => setPerPage(parseInt(event.target.value, 10))}
            />
          </div>
          <SubmitButton
            className={'mb-10'}
            color={Color.PRIMARY}
            fullWidth={true}
            label={'Add selected users'}
            size={ButtonSize.MEDIUM}
            type="submit"
            onClick={() => submitChanges(allUsers.filter((u) => u.isSelected).map((u) => u.email))}
            disabled={allUsers.length === 0 || !allUsers.some((u) => u.isSelected)}
          />
        </div>
      </Modal>
      {closeConfirmation && (
        <ConfirmationModal
          message="You are about to leave the menu without saving. Any unsaved changes will be lost. Do you want to proceed?"
          onClose={() => setCloseConfirmation(false)}
          onAccept={() => {
            setCloseConfirmation(false);
            onClose();
          }}
          acceptButton={
            <Button variant="contained" color="warning">
              Confirm
            </Button>
          }
        />
      )}
    </>
  );
}

export default AssignUsersToGroupBulkModal;
