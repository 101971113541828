import {useState, useContext, useEffect} from 'react';

import {Typography, Snackbar} from '@mui/material';

import AppContext from '../../../context/AppContext';
import EditProfileForm from './forms/EditProfileForm';
import ChangePasswordForm from './forms/ChangePasswordForm';

function ProfileAccount() {
  const [phoneNumberMissing, setPhoneNumberMissing] = useState(false);
  const context = useContext(AppContext);
  const user = context.user!;

  const [snackbarMessage, setSnackbarMessage] = useState<string>('');

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarMessage('');
  };

  useEffect(() => {
    if (!user.phoneNumber || user.phoneNumber === 'NULL') {
      setPhoneNumberMissing(true);
    }
  }, [user.phoneNumber]);

  return (
    <>
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-1">
          <div className="text-white font-bold text-4xl">My account</div>
          {/* <div className="text-gray-100 ">Desctiption</div> */}
        </div>
        <div className="flex flex-col gap-3">
          <div className="rounded-2xl bg-card-dark  px-7 py-4">
            <div className="text-white font-semibold text-3xl mb-4">Edit your profile</div>
            {/* <div className="text-gray-100 ">Desctiption</div> */}
            {phoneNumberMissing && (
              <Typography className="mt-25 mb-25" variant="h6" color="#CD5C5C">
                The phone number is not set, please update your profile information!
              </Typography>
            )}
            <EditProfileForm dispachSnackbarMessage={(message: string) => setSnackbarMessage(message)} />
          </div>
        </div>
        <div className="rounded-2xl bg-card-dark px-7 py-4">
          <div className="text-white font-semibold text-3xl mb-4">Change your password</div>
          {/* <div className="text-gray-100 ">Desctiption</div> */}

          <ChangePasswordForm dispachSnackbarMessage={(message: string) => setSnackbarMessage(message)} />
        </div>
      </div>
      <Snackbar open={snackbarMessage != ''} autoHideDuration={5000} onClose={handleClose} message={snackbarMessage} />
    </>
  );
}

export default ProfileAccount;
