import {useEffect, useReducer, useState} from 'react';
import {useOutletContext, useSearchParams} from 'react-router-dom';

import {ActiveUserRecord, ActiveUsersQueryResult} from '../../../../api/responses/PlatformUsersResponseTypes';
import {URLs, sendGetRequest, sendPostRequest} from '../../../../api';
import PlatformActiveUsersTable from './PlatformActiveUsersTable';
import {filterReducer} from './types';
import {Role} from '../../../../types';
import {PlatformUsersManagementOutletProps} from '../PlatformUsersManagement';

function PlatformActiveUsersView() {
  const {handleHttpError}: PlatformUsersManagementOutletProps = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const initialState = {
    nameQuery: searchParams.get('name') ?? null,
    emailQuery: searchParams.get('email') ?? null,
    role: (searchParams.get('role') as Role) ?? null,
    groupId: Number(searchParams.get('group')) ? Number(searchParams.get('group')) : null,
    page: Number(searchParams.get('page')) ? Number(searchParams.get('page')) : 0,
    rowsPerPage: Number(searchParams.get('limit')) ? Number(searchParams.get('limit')) : 10,
  };

  const [state, dispatch] = useReducer(filterReducer, initialState);

  const [activeUsers, setActiveUsers] = useState<Array<ActiveUserRecord>>([]);
  const [noTotalActiveUsers, setNoTotalActiveUsers] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleNameQueryChange = (value: string | null) => {
    dispatch({type: 'UPDATE_NAME_QUERY', payload: value});
    handlePageNoChange(0);
  };

  const handleEmailQueryChange = (value: string | null) => {
    dispatch({type: 'UPDATE_EMAIL_QUERY', payload: value});
    handlePageNoChange(0);
  };

  const handleRoleChange = (value: Role | null) => {
    dispatch({type: 'UPDATE_ROLE', payload: value});
    handlePageNoChange(0);
  };

  const handleGroupIdChange = (value: number | null) => {
    dispatch({type: 'UPDATE_GROUP', payload: value});
    handlePageNoChange(0);
  };

  const handlePageNoChange = (value: number) => {
    dispatch({type: 'UPDATE_PAGE_NO', payload: value});
  };

  const handleRowsPerPageChange = (value: number) => {
    dispatch({type: 'UPDATE_ROWS_PER_PAGE', payload: value});
    handlePageNoChange(0);
  };

  useEffect(() => {
    const filters: Array<{key: string; value: number | boolean | string}> = [];

    if (state.nameQuery) filters.push({key: 'name', value: state.nameQuery});
    if (state.emailQuery) filters.push({key: 'email', value: state.emailQuery});
    if (state.role) filters.push({key: 'role', value: state.role});
    if (state.groupId) filters.push({key: 'groupId', value: state.groupId});
    if (state.page) filters.push({key: 'page', value: state.page});
    if (state.rowsPerPage) filters.push({key: 'limit', value: state.rowsPerPage});

    const updatedParams = new URLSearchParams();
    filters.forEach((filter) => {
      updatedParams.set(filter.key, filter.value.toString());
    });
    setSearchParams(updatedParams, {replace: true});
    setIsLoading(true);
    const debounce = setTimeout(() => {
      sendGetRequest(URLs.activeUsers(filters.map((filter) => `${filter.key}=${filter.value}`).join('&')))
        .then((response) => {
          const data: ActiveUsersQueryResult = response.data;

          setActiveUsers(data.users);
          setNoTotalActiveUsers(data.metadata.total);
          setIsLoading(false);
        })
        .catch((err) => {
          if (err) {
            handleHttpError(err);
          }
        });
    }, 400);

    return () => {
      clearInterval(debounce);
    };
  }, [state, noTotalActiveUsers]);

  const blockUserCallback = (userId: number) => {
    sendPostRequest(URLs.changeUserStatus, {userId, status: 'blocked'})
      .then(() => {
        setNoTotalActiveUsers((prev) => prev - 1);
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  return (
    <PlatformActiveUsersTable
      users={activeUsers}
      noTotalUsers={noTotalActiveUsers}
      arrangementsState={state}
      nameQueryDispatch={handleNameQueryChange}
      emailQueryDispatch={handleEmailQueryChange}
      roleDispatch={handleRoleChange}
      groupIdDispatch={handleGroupIdChange}
      setPage={handlePageNoChange}
      setRowsPerPage={handleRowsPerPageChange}
      handleHttpError={handleHttpError}
      blockUser={blockUserCallback}
      isLoading={isLoading}
    />
  );
}

export default PlatformActiveUsersView;
