import {NavLink, Outlet, useOutletContext} from 'react-router-dom';
import {useContext, useEffect, useReducer, useState} from 'react';

import {Badge, Button, Typography} from '@mui/material';
import {Add} from '@mui/icons-material';

import {URLs, sendGetRequest} from '../../../api';
import {UsersManagementNotificationsResponse} from '../../../api/responses/UsersManagementNotificationsTypes';
import AppContext from '../../../context/AppContext';
import AddNewMemberModal from './AddNewMemberModal';

export type UsersManagementOutletProps = {
  handleHttpError: (err: any) => void;
  update: number;
  updateCallback: () => void;
};

function UsersManagement() {
  const {handleHttpError}: UsersManagementOutletProps = useOutletContext();
  const {user} = useContext(AppContext);

  const [update, forceUpdate] = useReducer((x) => x + 1, 0);

  const [isNewMemberModalOpen, setIsNewMemberModalOpen] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<{pendingUsers: number}>();

  const updateCallback = () => {
    forceUpdate();
  };

  useEffect(() => {
    sendGetRequest(URLs.getModuleUsersManagementNotifications(user!.activeModule!.moduleId))
      .then((response) => {
        const data: UsersManagementNotificationsResponse = response.data;

        setNotifications(data);
      })
      .catch((err) => {
        handleHttpError(err);
      });
  }, [update]);

  const UsersManagementTabs: Array<{name: string; path: string; badgeCount?: number}> = [
    {
      name: 'ACTIVE',
      path: '',
    },
    {
      name: 'PENDING',
      path: 'pending',
      badgeCount: notifications?.pendingUsers ?? 0,
    },
    {
      name: 'ARCHIVED',
      path: 'archived',
    },
  ];

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-row justify-between items-center">
        <span className="font-bold text-2xl">Users management</span>
        <Button variant="contained" color="primary" onClick={() => setIsNewMemberModalOpen(true)} startIcon={<Add />}>
          Add new member
        </Button>
      </div>

      <div>
        <nav className="flex gap-8 justify-around">
          {UsersManagementTabs.map((tab) => (
            <NavLink key={tab.name} to={tab.path} replace={true} className="no-underline" end>
              {({isActive}) => (
                <Badge badgeContent={tab.badgeCount !== 0 ? tab.badgeCount : null} color="primary">
                  <Typography style={{color: isActive ? 'inherit' : '#808080'}} variant="h7">
                    {tab.name}
                  </Typography>
                </Badge>
              )}
            </NavLink>
          ))}
        </nav>
        <div className="py-4 w-full">
          <Outlet context={{handleHttpError, update, updateCallback}} />
        </div>
      </div>

      {isNewMemberModalOpen && (
        <AddNewMemberModal
          isOpen={isNewMemberModalOpen}
          onSuccessCallback={updateCallback}
          onClose={() => setIsNewMemberModalOpen(false)}
        />
      )}
    </div>
  );
}

export default UsersManagement;
