import {useEffect, useState, useContext} from 'react';
import {useNavigate} from 'react-router-dom';

import {EmojiEvents, Category as CategoryIcon, Close} from '@mui/icons-material';
import {IconButton, Typography} from '@mui/material';

import {Form as FormComponent} from '..';
import AppContext from '../../context/AppContext';
import {CreateProblemRequest, sendGetRequest, sendRequest, URLs} from '../../api';
import {NewProblemProps as Props} from './NewProblem.props';
import {Test} from '../../types/entities/Test';
import {Category} from '../../types/entities/Category';
import {ModuleType} from '../../types/entities/ModuleType';
import {UpdateProblemRequest} from '../../api/Requests';

export const Form = (props: Props) => {
  const navigate = useNavigate();

  const {dispatchError} = useContext(AppContext);
  const [errors] = useState<Record<string, any>>({});
  const [allCategories, setAllCategories] = useState<Array<Category>>();

  useEffect(() => {
    sendGetRequest('/category/all')
      .then((response) => {
        setAllCategories(response.data);
      })
      .catch((err) => {
        if (err) {
          if (err.response) {
            dispatchError({errorMessage: err.response.error});
          } else {
            dispatchError({errorMessage: 'There was an error. Please try again later'});
          }
        }
      });
  }, []);

  const handleSubmit = async (data: any) => {
    // making sure we don't send data we don't need
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {difficulty, test, problemLanguages, ...others} = data;

    // Change this when we want to support private problems
    const DEFAULT_VISIBILITY = true;

    let request = null;

    if (!props.isEdit) {
      request = {
        title: data.title,
        description: data.description,
        categories: data.categories,
        languages: problemLanguages,
        difficulty: difficulty ?? 'easy',
        tests: test
          ? test.map((test: Test, index: number) => ({
              id: test.id,
              input: test.input,
              output: test.output,
              grade: parseInt(test.grade, 10),
              isVisible: index !== 0 ? Boolean(test.isVisible) : true,
            }))
          : [],
        visible: DEFAULT_VISIBILITY,
      } as CreateProblemRequest;
    } else {
      request = {
        title: data.title,
        description: data.description,
        categories: data.categories,
        languages: problemLanguages,
        difficulty: difficulty ?? 'easy',
        tests: test
          ? test.map((test: Test, index: number) => ({
              id: test.id,
              input: test.input,
              output: test.output,
              grade: parseInt(test.grade, 10),
              isVisible: index !== 0 ? Boolean(test.isVisible) : true,
            }))
          : [],
        visible: DEFAULT_VISIBILITY,
      } as UpdateProblemRequest;
    }
    await sendRequest(props.method, props.url, request)
      .then((response) => {
        if (response) {
          if (response.status === 200 || response.status === 201) {
            if (props.onSuccess) {
              props.onSuccess();
            }

            if (response.data?.id) {
              navigate(`/problem/${response.data?.id}`, {replace: true});
            }
          }
        }
      })
      .catch((err) => {
        if (err) {
          if (
            err.response &&
            err.response.data &&
            err.response.data.cause &&
            err.response.data.cause.details &&
            err.response.data.cause.details[0] &&
            err.response.data.cause.details[0].message
          ) {
            dispatchError({errorMessage: err.response.data.cause.details[0].message});
          } else if (err.response.data.error) {
            dispatchError({errorMessage: err.response.data.error});
          } else {
            dispatchError({errorMessage: 'There was an error. Please try again later'});
          }
        }
      });
  };

  return (
    <div className="flex flex-col justify-between px-8 py-3">
      <div className="absolute right-1 px-4">
        <IconButton onClick={props.onClose}>
          <Close color="success" sx={{color: 'white'}} />
        </IconButton>
      </div>
      <Typography align="center" variant="h3">
        {props.isEdit ? `Edit '${props.title}' problem` : 'Create a new problem'}
      </Typography>
      <FormComponent
        button={{
          className: 'mt-25 mb-10',
          label: 'Submit',
          onClick: handleSubmit,
        }}
        formClass="mt-25"
        fullWidth={true}
        onFirstFieldChange={() => props.dataWasEditedCallback && props.dataWasEditedCallback()}
        inputs={[
          [
            {
              id: 'title',
              error: errors['title'],
              placeholder: 'Problem title',
              rules: {
                length: [
                  {
                    cond: 'not-empty',
                    message: 'You must include a title',
                  },
                ],
              },
              startAdornment: <EmojiEvents />,
              type: 'input',
              value: props.title,
            },
          ],
          [
            {
              id: 'difficulty',
              error: errors['difficulty'],
              options: ['easy', 'medium', 'hard'],
              placeholder: 'Difficulty',
              rules: {},
              type: 'dropdown',
              value: props.difficulty,
            },
            // for example on how to use the 'transfer-list' type check this file in commit 3f218bc47fe5b328f8733820aa7395bce7f72aa0
            {
              id: 'categories',
              error: errors['categories'],
              placeholder: 'Categories',
              rules: {},
              startAdornment: <CategoryIcon />,
              type: 'multiselect',
              selectedOptions: props.categories.map((category) => ({label: category.title, value: category.id})) ?? [],
              options: allCategories?.map((category) => ({label: category.title, value: category.id})) ?? [],
            },
          ],
          [
            {
              id: 'description',
              error: errors['description'],
              minRows: 2,
              placeholder: 'Description',
              rules: {
                length: [
                  {
                    cond: 'not-empty',
                    message: 'You must include a description',
                  },
                ],
              },
              type: 'markdown',
              value: props.description,
            },
          ],
          [
            {
              id: 'problemLanguages',
              type: 'problem-languages',
              error: errors['problemLanguagess'],
              placeholder: 'Languages',
              rules: {
                length: [
                  {
                    cond: 'greater',
                    value: 1,
                    message: 'You must include at least one language per problem',
                  },
                ],
              },
              languages: props.languages,
            },
          ],
          [
            {
              id: 'test',
              error: errors['test'],
              maxNoEntries: 10,
              rules: {
                length: [
                  {
                    cond: 'not-empty',
                    message: 'You must include atleast one test',
                  },
                ],
              },
              title: 'Tests',
              type: 'test',
              value: props.test,
            },
          ],
        ]}
      />
    </div>
  );
};
