import {useContext, useEffect, useMemo, useState} from 'react';
import Select from 'react-select';

import {useTheme} from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Typography,
  IconButton,
  TablePagination,
  Skeleton,
  Tooltip,
} from '@mui/material/';
import {Archive, Edit, PersonRemove} from '@mui/icons-material';

import {ActiveUserRecord} from '../../../../api/responses/PlatformUsersResponseTypes';
import {URLs, request, sendGetRequest, sendPutRequest} from '../../../../api';
import {dropdownStylesConfig} from '../../../../components/form/dropdown/Dropdown';
import {contestsPaginationStyle} from '../../../contests/contests-table/contest-table-styles';
import {ActiveUsersArrangementsState, UpdateUserParams} from './types';
import {Group} from '../../../../types/entities/Group';
import EditActiveUserModal from './EditActiveUserModal';
import {fromRoleCodeToModuleString, ModuleRole, Role} from '../../../../types/entities/Role';
import AppContext from '../../../../context/AppContext';
import RemoveModuleMemberModal from './RemoveModuleMemberModal';
import {formatFullName} from '../../../../utils/UsersNames';

type ActiveUsersTableProps = {
  users: Array<ActiveUserRecord>;
  noTotalUsers: number;
  arrangementsState: ActiveUsersArrangementsState;
  handleHttpError: (err: any) => void;
  nameQueryDispatch: (value: string | null) => void;
  emailQueryDispatch: (value: string | null) => void;
  roleDispatch: (value: Role | null) => void;
  groupIdDispatch: (value: number | null) => void;
  setPage: (value: number) => void;
  setRowsPerPage: (value: number) => void;
  archiveMember: (moduleId: number, memberId: number) => void;
  isLoading: boolean;
};

function ActiveUsersTable({users, noTotalUsers, arrangementsState, handleHttpError, ...props}: ActiveUsersTableProps) {
  const {user, dispatchError} = useContext(AppContext);
  const theme = useTheme();
  const dropdownStyles = dropdownStylesConfig();

  const [allGroups, setAllGroups] = useState<Array<Group>>([]);
  const [editUserId, setEditUserId] = useState<number | null>(null);
  const [memberToRemoveId, setMemberToRemoveId] = useState<number | null>(null);

  useEffect(() => {
    sendGetRequest(URLs.accessibleUserModuleGroups(user!.activeModule!.moduleId))
      .then((response) => {
        setAllGroups(response.data);
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  }, []);

  useEffect(() => {
    setEditUserId(null);
  }, [arrangementsState]);

  const updateUserCallback = ({
    id,
    firstName,
    lastName,
    nickName,
    role,
    groupsIds,
  }: Omit<UpdateUserParams, 'email'>) => {
    if (user?.activeModule?.moduleId) {
      sendPutRequest(URLs.updateModuleMember(user.activeModule.moduleId, id), {
        firstName,
        lastName,
        nickName,
        role,
        groupsIds,
      })
        .then(() => {
          setEditUserId(null);
          props.setPage(0);
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.error) {
            dispatchError({errorMessage: err.response.data.error, buttonText: 'Okey'});
          } else {
            dispatchError({errorMessage: 'There was an error. Please try again later', buttonText: 'Okey'});
          }
        });
    }
  };

  const removeMemberCallback = () => {
    if (memberToRemoveId) {
      request<never, never, never>(URLs.unassignMemberFromModule(user!.activeModule!.moduleId, memberToRemoveId), {
        method: 'DELETE',
        successCallback: () => {
          setMemberToRemoveId(null);
          props.setPage(0);
        },
        errorCallback: () => {
          dispatchError({
            errorMessage: 'There was an error. Please try again later.',
            redirectURL: '/module-admin//users',
          });
        },
      });
    }
  };

  const groupOptions = useMemo(
    () => [{value: null, label: 'All'}, ...allGroups.map((group: Group) => ({value: group.id, label: group.name}))],
    [allGroups]
  );

  const roleOptions = [
    {value: null, label: 'All'},
    ...(Object.keys(ModuleRole) as Array<keyof typeof ModuleRole>).map((key) => ({
      value: ModuleRole[key],
      label: key.replace(/_/g, ' '),
    })),
  ];

  return (
    <div className="flex flex-col items-center justify-center">
      <TableContainer className="bg-background-light" sx={{overflowX: 'visible'}}>
        <Table
          sx={{
            '& .MuiTableRow-root:hover': {
              backgroundColor: 'transparent',
            },
            '& .MuiTableCell-root': {
              borderColor: theme.palette.card.light,
              color: theme.palette.card.contrastText,
            },
            backgroundColor: theme.palette.card.dark,
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Groups</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableCell>
              <input
                className="w-full p-[15px] h-14 rounded outline-none text-white border-none bg-inputBox-backgroundColor"
                placeholder="Search by name or nickname"
                value={arrangementsState.nameQuery as string}
                onChange={(event) => props.nameQueryDispatch(event.target.value)}
              />
            </TableCell>
            <TableCell>
              <input
                className="w-full p-[15px] h-14 rounded outline-none text-white border-none bg-inputBox-backgroundColor"
                placeholder="Search email"
                value={arrangementsState.emailQuery as string}
                onChange={(event) => props.emailQueryDispatch(event.target.value)}
              />
            </TableCell>
            <TableCell>
              <Select
                styles={dropdownStyles}
                options={roleOptions}
                isSearchable={false}
                onChange={(event) => {
                  const role = (event as {value: Role | null; label: string}).value;
                  props.roleDispatch(role);
                }}
                menuPlacement="auto"
              />
            </TableCell>
            <TableCell colSpan={2}>
              <Select
                styles={dropdownStyles}
                options={groupOptions}
                onChange={(event) => {
                  const groupId = (event as {value: number | null; label: string}).value;
                  props.groupIdDispatch(groupId);
                }}
                menuPlacement="auto"
              />
            </TableCell>
            {!props.isLoading &&
              users.map((member) => (
                <TableRow key={member.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                  <TableCell>
                    <div className="flex flex-col items-start">
                      <span>{`${member.firstName} ${member.lastName}`}</span>
                      <span className="text-gray-400">{`${member.nickName}`}</span>
                    </div>
                  </TableCell>
                  <TableCell>{member.email}</TableCell>
                  <TableCell>{fromRoleCodeToModuleString(member.role)}</TableCell>
                  <TableCell>
                    <div className="flex flex-row flex-wrap w-36 justify-center">
                      {member.groupsIds.length > 0 ? (
                        member.groupsIds.map((groupId: number) => (
                          <div key={groupId} className="bg-card-light px-2 py-1 w-fit rounded-xl text-center mb-1">
                            {allGroups.find((group) => group.id == groupId)?.name}
                          </div>
                        ))
                      ) : (
                        <span className="text-center">No assigned groups</span>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Edit">
                      <IconButton sx={{color: theme.palette.common.white}}>
                        <Edit onClick={() => setEditUserId(member.id)} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Archive">
                      <IconButton sx={{color: theme.palette.common.white}}>
                        <Archive onClick={() => props.archiveMember(user!.activeModule!.moduleId, member.id)} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={member.id != user?.id ? 'Remove' : 'You can leave modules in profile page'}>
                      <span>
                        <IconButton disabled={member.id == user?.id} sx={{color: theme.palette.error.dark}}>
                          <PersonRemove onClick={() => setMemberToRemoveId(member.id)} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            {props.isLoading &&
              Array.from({length: arrangementsState.rowsPerPage}).map((_, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <Skeleton animation="wave" variant="text" width={250} />
                    <Skeleton animation="wave" variant="text" width={150} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="circular" />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {users && users.length === 0 && !props.isLoading && (
          <Typography className="p-5" align="center" variant="h5">
            No active users found based on the criteria.
          </Typography>
        )}
        <TablePagination
          sx={contestsPaginationStyle(theme)}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={noTotalUsers}
          rowsPerPage={arrangementsState.rowsPerPage}
          page={arrangementsState.page}
          onPageChange={(event, page) => props.setPage(page)}
          onRowsPerPageChange={(event) => props.setRowsPerPage(parseInt(event.target.value, 10))}
        />
      </TableContainer>

      {editUserId && (
        <EditActiveUserModal
          user={users.find((member) => member.id === editUserId)!}
          allGroups={allGroups}
          closeCallback={() => setEditUserId(null)}
          updateUserCallback={updateUserCallback}
        />
      )}

      {memberToRemoveId && (
        <RemoveModuleMemberModal
          closeCallback={() => setMemberToRemoveId(null)}
          removeModuleMemberCallback={removeMemberCallback}
          userName={formatFullName(
            users.find((u) => u.id === memberToRemoveId)!.firstName,
            users.find((u) => u.id === memberToRemoveId)!.lastName
          )}
        />
      )}
    </div>
  );
}

export default ActiveUsersTable;
