import {useContext, useState} from 'react';

import {Button, Checkbox, Tooltip, Typography} from '@mui/material';

import AppContext from '../../context/AppContext';
import {UserSettings} from '../../types/entities/UserSettings';

type LeaderboardVisibilityToggleProps = {
  currentIsCheckedSetting: boolean;
  saveCallback: (partialNewUserSettings: UserSettings) => Promise<boolean>;
};

function LeaderboardVisibilityToggle({currentIsCheckedSetting, saveCallback}: LeaderboardVisibilityToggleProps) {
  const context = useContext(AppContext);

  const [isChecked, setIsChecked] = useState<boolean>(currentIsCheckedSetting || false);
  const [isSavedSuccessfully, setIsSavedSuccessfully] = useState<boolean>(false);

  const toggleIsChecked = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <div className="flex flex-row items-center">
        <div className="flex flex-row items-center w-full">
          <Checkbox
            checked={isChecked}
            onChange={toggleIsChecked}
            name={'leaderboard-visibility-toggle'}
            size="medium"
          />
          <Typography variant="subtitle1">{'Appear in leaderboard'}</Typography>
        </div>
      </div>
      <div className="flex flex-row justify-between items-center w-full mt-1">
        <Button
          className="w-60"
          variant="contained"
          color="secondary"
          size="medium"
          onClick={async () => {
            setIsSavedSuccessfully(await saveCallback({appearInLeaderboard: isChecked}));
          }}
        >
          Save
        </Button>
        <div>{isSavedSuccessfully && <Typography variant="body2">Saved successfully!</Typography>}</div>
      </div>
    </>
  );
}

export default LeaderboardVisibilityToggle;
