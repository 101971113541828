import {useContext, useEffect, useReducer, useState} from 'react';
import {useOutletContext} from 'react-router-dom';

import {useTheme} from '@mui/material/styles';
import {Table, TableBody, TableCell, TableRow, TableContainer, TableHead, Typography, IconButton} from '@mui/material/';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

import {sendGetRequest, sendPostRequest, URLs} from '../../../../api';
import {User} from '../../../../types';
import PendingUserDeleteModal from '../../../../components/modal/ConfirmationModal';
import {UsersManagementOutletProps} from '.././UsersManagement';
import AppContext from '../../../../context/AppContext';
import {fromRoleCodeToModuleString} from '../../../../types/entities/Role';

export const PendingUsersView = () => {
  const {user} = useContext(AppContext);
  const theme = useTheme();

  const [update, forceUpdate] = useReducer((x) => x + 1, 0);

  const {handleHttpError, updateCallback}: UsersManagementOutletProps = useOutletContext();

  const [pendingUsers, setPendingUsers] = useState<Array<User>>([]);

  const [selectedUserID, setSelectedUserID] = useState<number | undefined>(undefined);

  const handleJoinRequest = (moduleId: number, userId: number, isAccept: boolean) => {
    sendPostRequest(URLs.reviewModuleMemberJoinRequest(moduleId, userId), {review: isAccept ? 'accepted' : 'rejected'})
      .then(() => {
        forceUpdate();
        setSelectedUserID(undefined);
        updateCallback();
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  useEffect(() => {
    sendGetRequest(URLs.pendingModuleMembers(user!.activeModule!.moduleId))
      .then((response) => {
        setPendingUsers(
          response.data.users.map((user: any) => ({...user, role: fromRoleCodeToModuleString(user.role)}))
        );
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  }, [update]);

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        {pendingUsers && (
          <TableContainer className="bg-background-light">
            <Table
              sx={{
                '& .MuiTableRow-root:hover': {
                  backgroundColor: 'transparent',
                },
                '& .MuiTableCell-root': {
                  borderColor: theme.palette.card.light,
                  color: theme.palette.card.contrastText,
                },
                backgroundColor: theme.palette.card.dark,
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {pendingUsers.map((pendingUser) => (
                  <TableRow key={pendingUser.id}>
                    <TableCell>
                      <div className="flex flex-col items-start">
                        <span>{`${pendingUser.firstName} ${pendingUser.lastName}`}</span>
                        <span className="text-gray-400">{`${pendingUser.nickName}`}</span>
                      </div>
                    </TableCell>
                    <TableCell>{pendingUser.email}</TableCell>
                    <TableCell>{pendingUser.role}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => handleJoinRequest(user!.activeModule!.moduleId, pendingUser.id, true)}
                        sx={{color: theme.palette.primary.main}}
                      >
                        <DoneIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => setSelectedUserID(pendingUser.id)}
                        sx={{color: theme.palette.common.white}}
                      >
                        <ClearIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {pendingUsers && pendingUsers.length === 0 && (
              <Typography className="p-5" align="center" variant="h5">
                No pending users
              </Typography>
            )}
          </TableContainer>
        )}
      </div>

      {selectedUserID && (
        <PendingUserDeleteModal
          message={"This user's data will be lost, are you sure?"}
          onClose={() => setSelectedUserID(undefined)}
          onAccept={() => handleJoinRequest(user!.activeModule!.moduleId, selectedUserID, false)}
        />
      )}
    </>
  );
};
