import React, {ReactElement, useContext, useRef} from 'react';

import AppContext from '../../context/AppContext';
import {useWidth} from '../../hooks/Size';
import {PageProps} from '../../types';
import {Menu} from '../menu/Menu';

function toArray(children?: ReactElement | Array<ReactElement>) {
  if (!children) {
    return [];
  }
  return Array.isArray(children) ? children : [children];
}

/**
 * Home page
 */
export const Page = (props: PageProps) => (
  <>
    {toArray(props.children).map((component, index) =>
      React.cloneElement(component, {
        key: index,
        // parentwidth: width,
      })
    )}
  </>
);
