import {useContext, ReactNode} from 'react';
import {NavLink, Outlet} from 'react-router-dom';

import {
  People,
  School,
  BorderColorRounded,
  AutoStories,
  Group,
  FileUpload,
  Category,
  Class,
  ContentPaste,
  Extension,
} from '@mui/icons-material';
import {Typography} from '@mui/material';

import {Page} from '../../components';
import AppContext from '../../context/AppContext';
import {Role} from '../../types';

function PlatformManagementPage() {
  const {dispatchError, user} = useContext(AppContext);

  const staffNav: Array<{name: string; path: string; icon: ReactNode}> = [];

  if (user && user.role === Role.ADMIN) {
    staffNav.push(...getNav());
  }
  const handleHttpError = (err: any, redirectUrl?: string) => {
    if (err) {
      dispatchError({errorMessage: err, redirectURL: redirectUrl ?? undefined});
    } else {
      dispatchError({
        errorMessage: 'There was an error. Please try again later.',
        redirectURL: redirectUrl ?? undefined,
      });
    }
  };

  return (
    <Page>
      <div className="grid grid-cols-12 w-full">
        <aside className="col-span-3 bg-card-main rounded-2xl min-h-[512px] max-h-[80vh] h-screen sticky top-[96px] ">
          <nav className="flex flex-col px-6 py-4">
            {staffNav.map((tab) => (
              <NavLink
                key={`${tab.name}`}
                to={tab.path}
                replace={true}
                className="flex items-center rounded-lg h-12 px-4 mb-2 no-underline"
                style={({isActive}) => ({
                  backgroundColor: isActive ? '#16a137' : 'inherit',
                })}
              >
                {tab.icon}
                <Typography variant="body1">{tab.name}</Typography>
              </NavLink>
            ))}
          </nav>
        </aside>
        <div className="col-span-9">
          <div className="px-6 py-4 w-full">
            <Outlet context={{handleHttpError}} />
          </div>
        </div>
      </div>
    </Page>
  );
}

export default PlatformManagementPage;

const getNav = (): Array<{name: string; path: string; icon: ReactNode}> => [
  {
    name: 'Platform Users Management',
    path: 'users',
    icon: <People fontSize="medium" className="text-white mr-2" />,
  },
  {
    name: 'Authors Management',
    path: 'authors',
    icon: <BorderColorRounded fontSize="medium" className="text-white mr-2" />,
  },

  {
    name: 'Modules Management',
    path: 'modules',
    icon: <Extension fontSize="medium" className="text-white mr-2" />,
  },

  {
    name: 'Import users',
    path: 'import-users',
    icon: <FileUpload fontSize="medium" className="text-white mr-2" />,
  },
  {
    name: 'Categories Management',
    path: 'categories',
    icon: <Category fontSize="medium" className="text-white mr-2" />,
  },
];
