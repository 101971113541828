import {createContext} from 'react';

import {User} from '../types/entities/User';
import ErrorModalDataType from '../types/generics/ErrorModalDataType';
import {ModuleEntry} from '../types/entities/ModuleType';

export class ContextData {
  user: undefined | User;
  loginCallback: (data: any) => void;
  logoutCallback: () => void;
  updateData: (data: any) => void;
  languages: Record<number, string>;
  modalError: ErrorModalDataType;
  dispatchError: ({
    errorMessage,
    buttonText,
    redirectURL,
    hasCloseIconButton,
  }: {
    errorMessage: string;
    buttonText?: string;
    redirectURL?: string;
    hasCloseIconButton?: boolean;
  }) => void;
  clearError: () => void;
  changeModuleContext: (newModuleEntry: ModuleEntry) => void;

  public static TIME_LIMIT = 60000; // 1 minute before the pending key is deleted from the local storage

  constructor(
    user?: User,
    loginCallback?: (data: any) => void,
    logoutCallback?: () => void,
    languages?: Record<number, string>,
    modalError?: ErrorModalDataType,
    dispatchError?: ({
      errorMessage,
      buttonText,
      redirectURL,
      hasCloseIconButton,
    }: {
      errorMessage: string;
      buttonText?: string;
      redirectURL?: string;
      hasCloseIconButton?: boolean;
    }) => void,
    clearError?: () => void,
    updateData?: (data: any) => void,
    changeModuleContext?: (newModuleEntry: ModuleEntry) => void
  ) {
    this.user = user;
    this.languages = languages ?? {};

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.loginCallback = loginCallback ?? ((_data: any) => {});
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.updateData = updateData ?? ((_data: any) => {});
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.logoutCallback = logoutCallback ?? (() => {});

    this.modalError = modalError ?? {isErrorEnabled: false, message: null};

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.dispatchError = dispatchError ?? (() => {});
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.clearError = clearError ?? (() => {});

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.changeModuleContext = changeModuleContext ?? (() => {});
  }

  updateUser(newUser: User) {
    this.user = newUser;
    this.updateData(newUser);
    newUser.store();
  }

  checkTimeLimit(pending: string, pendingKey: string) {
    const date = new Date(pending);
    const now = new Date();

    if (now.getTime() - date.getTime() >= ContextData.TIME_LIMIT) {
      localStorage.removeItem(pendingKey);
      window.dispatchEvent(new Event('storage'));
    }
  }
}

export const defaultContext = new ContextData();
export default createContext<ContextData>(defaultContext);
