import {useContext, useState} from 'react';
import Select from 'react-select';

import {Alert, Button, IconButton, Modal} from '@mui/material';
import {Add, Close} from '@mui/icons-material';

import {fromRoleStringToModuleCode, ModuleRole, ModuleRoleCode} from '../../../types/entities/Role';
import {dropdownStylesConfig} from '../../../components/form/dropdown/Dropdown';
import {Pattern} from '../../../validation/Pattern';
import {request, URLs} from '../../../api';
import AppContext from '../../../context/AppContext';

type AddNewMemberModalProps = {
  isOpen: boolean;
  onSuccessCallback: () => void;
  onClose: () => void;
};

const roleOptions = [
  ...(Object.keys(ModuleRole) as Array<keyof typeof ModuleRole>).map((key) => ({
    value: ModuleRole[key],
    label: key.replace(/_/g, ' '),
  })),
];

function AddNewMemberModal({isOpen, onSuccessCallback, onClose}: AddNewMemberModalProps) {
  const {user, dispatchError} = useContext(AppContext);

  const dropdownStyles = dropdownStylesConfig();

  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<ModuleRole | null>(null);

  const onAddNewMember = (userEmail: string, roleCode: string) => {
    user?.activeModule?.moduleId &&
      request<
        {},
        {
          userEmail: string;
          roleCode: ModuleRoleCode;
        },
        {}
      >(URLs.assignMemberToModule(user.activeModule.moduleId), {
        method: 'POST',
        body: {
          userEmail,
          roleCode: fromRoleStringToModuleCode(roleCode),
        },
        successCallback: () => {
          onClose();
          onSuccessCallback();
        },
        errorCallback: () => {
          dispatchError({errorMessage: 'There was an error. Please try again later.'});
        },
      });
  };

  return (
    <Modal open={isOpen}>
      <div className="top-2/4 left-2/4 w-[40%] h-fit p-3 absolute translate-x-[-50%] translate-y-[-50%] bg-background-default">
        <div className="absolute right-1 px-4">
          <IconButton onClick={onClose}>
            <Close color="success" sx={{color: 'white'}} />
          </IconButton>
        </div>
        <div className="flex flex-col justify-center items-center gap-4 p-2">
          <span className="text-2xl text-center font-bold">Add a new member to the modules</span>

          {/* <span className="pt-2">Desc...</span> */}

          <input
            className="w-full p-[15px] h-14 rounded outline-none text-white border-none bg-inputBox-backgroundColor"
            placeholder="User email..."
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />

          <Select
            styles={dropdownStyles}
            options={roleOptions}
            isSearchable={false}
            onChange={(event) => {
              const role = (event as {value: ModuleRole | null; label: string}).value;
              setRole(role);
            }}
            placeholder="Select role..."
            menuPlacement="auto"
          />

          {role === ModuleRole.COURSE_ADMIN && (
            <Alert severity="warning">
              A new Course-Admin will have the same rights as you, and can, for example, delete groups, add new
              Course-Admins and remove users (including you). Be careful who you assign this role to.
            </Alert>
          )}

          <div className="flex flex-row justify-center gap-4 mt-6 w-full">
            <Button
              variant="contained"
              color="primary"
              className="mr-4"
              startIcon={<Add />}
              disabled={!Pattern.emailRegex.test(email) || !role}
              onClick={() => email && role && onAddNewMember(email, role)}
            >
              Add member
            </Button>

            <Button variant="contained" color="gridSecondary" startIcon={<Close />} onClick={() => onClose()}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddNewMemberModal;
