import {useState, useReducer} from 'react';

import {Grid, Modal, Typography, Button as MuiButton, Tooltip} from '@mui/material';
import {styled} from '@mui/system';
import {Close, Done} from '@mui/icons-material';

import {Option} from '../Option';
import {Button} from '../button/Button';
import {updateProperty} from '../../../utils';
import {Submission} from '../../../types';
import {toChips} from '../../problems-table/Chips';
import {LanguageIds, languageOptions} from '../../../types/entities/Language';
import {CodeEditor} from '../../code-editor/CodeEditor';

const Grade = styled(Grid)(() => ({
  alignItems: 'center',
  borderRadius: '0 10px 10px 0',
  boxSizing: 'border-box',
  display: 'flex',
  fontWeight: 600,
  justifyContent: 'center',
  padding: '10px 20px 10px 20px',
}));

const Options = styled(Grid)(() => ({
  backgroundColor: '#D6D6D6',
  borderRadius: '0 0 10px 10px',
  boxSizing: 'border-box',
  padding: '10px',
}));

interface CardProps {
  index: number;
  submission: Submission;
  setCode: (newCode: string, newLanguageId: number) => void;
  userDetails?: {
    email: string;
  };
}

export const Card = (props: CardProps) => {
  const [showOptions, setShowOptions] = useState(false);
  const [show, setShow] = useState<Record<string, any>>({});
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const options = Option.fromSubmission(props);

  const [showDetailedError, setShowDetailedError] = useState<string | false>(false);

  if (props.submission === Submission.PENDING) {
    return (
      <div className="flex flex-row bg-white rounded-xl">
        <Grid item xs>
          <Grid container direction="column" sx={{padding: '10px'}}>
            <Grid item>
              <Typography variant="h5_black">{`Submission ${props.index}`.toUpperCase()}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1_black">Pending...</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grade item className="pending-submission" xs="auto">
          0
        </Grade>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div onClick={() => setShowOptions(!showOptions)} className="flex flex-row bg-white rounded-xl cursor-pointer">
        <div className="flex flex-col p-2 justify-between w-full">
          <Typography variant="h5_black">{`Submission ${props.index}`.toUpperCase()}</Typography>
          <Typography variant="subtitle1_black">{props.submission.dateString()}</Typography>
          {props.userDetails && <Typography variant="subtitle1_black">{props.userDetails.email}</Typography>}
        </div>
        <div className="flex items-center mr-2">
          {toChips('language', [
            languageOptions.find((lang) => lang.languageId == props.submission.language_id)?.label ?? 'Unknown',
          ])}
        </div>
        <Grade
          item
          className={`${props.submission.error ? 'failed' : 'passed'}-submission`}
          xs="auto"
          style={{width: '64px'}}
        >
          {props.submission.grade}
        </Grade>
      </div>
      <div className="pt-2 pr-1 pl-3">
        {showOptions && (
          <Options container direction="column" spacing={1}>
            {props.submission.results.map((result, index: number) => (
              <div key={index} className="flex flex-row justify-between py-2 w-full">
                <div>
                  <Typography variant="h5_black">{`Test ${index + 1}, ${result.status.toLowerCase()}`}</Typography>
                </div>
                <div>
                  <Typography variant="h5_black">
                    {result.status.toLowerCase() === 'passed' ? <Done color="success" /> : <Close color="warning" />}
                  </Typography>
                </div>
              </div>
            ))}
            {props.submission.error && (
              <div className="flex flex-col w-full break-words">
                {props.submission.language_id ? (
                  <div className="flex flex-row justify-between gap-2 items-center mb-2">
                    <Typography variant="h5_black">Compilation error</Typography>
                    <Tooltip title="Show detailed error log" arrow>
                      <MuiButton onClick={() => setShowDetailedError(props.submission.error ?? false)} size="small">
                        Show details
                      </MuiButton>
                    </Tooltip>
                  </div>
                ) : (
                  <Typography variant="h5_black">Compilation error</Typography>
                )}
                <div className="flex flex-col gap-2 text-black ">
                  {(() => {
                    let errors = [];

                    switch (props.submission.language_id) {
                      case LanguageIds.JAVA:
                        errors = parseJavaErrors(props.submission.error);
                        break;
                      case LanguageIds.C:
                      case LanguageIds.CPP:
                        errors = parseGccErrors(props.submission.error);
                        break;
                      case LanguageIds.PYTHON:
                        errors = parsePythonErrors(props.submission.error);
                        break;
                      default:
                        return <Typography variant="subtitle1_black">{props.submission.error}</Typography>;
                    }

                    return errors.length > 0 ? (
                      errors.map((error, index) => (
                        <div key={index} className="flex flex-row gap-2 items-center">
                          <Typography variant="subtitle1_black">{error}</Typography>
                        </div>
                      ))
                    ) : (
                      <div className="w-full text-center text-black font-medium">
                        Please check the detailed errors...
                      </div>
                    );
                  })()}
                </div>
              </div>
            )}
            <div className="flex flex-row justify-end gap-2 items-end">
              {options.map((option) => (
                <Button
                  key={`option-${option.id}`}
                  option={option}
                  show={show[option.id!]}
                  setShow={(newShow: boolean) => {
                    setShow(updateProperty(show, option.id!, newShow));
                    forceUpdate();
                  }}
                />
              ))}
            </div>
          </Options>
        )}
      </div>

      {showDetailedError && (
        <Modal open={true} onClose={() => setShowDetailedError(false)}>
          <div className="flex flex-col bg-background-default top-2/4 left-2/4 w-[70%] h-[60%] p-4 absolute translate-x-[-50%] translate-y-[-50%] overflow-y-scroll">
            <CodeEditor className="min-h-[50vh]" code={showDetailedError} readOnly={true} enableMinimap />
          </div>
        </Modal>
      )}
    </div>
  );
};

function parseGccErrors(output: string): Array<string> {
  const errorBlocks = output.split(/(?=^(?!\s).*error:)/gm).filter((block) => block.trim() !== '');
  return errorBlocks;
}
function parseJavaErrors(output: string): Array<string> {
  const errorBlocks = output.split(/(?=^[^ \t].*\.java:\d+: error:)/gm).filter((block) => block.trim() !== '');
  return errorBlocks;
}

function parsePythonErrors(output: string): Array<string> {
  const errorBlocks = output
    .split(/(?=^Traceback \(most recent call last\):|^(?!\s).*?(?:Error|Exception):)/gm)
    .filter((block) => block.trim() !== '');
  return errorBlocks;
}
