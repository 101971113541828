import {useContext, useState, useReducer} from 'react';
import {useNavigate} from 'react-router';

import {MailOutline, LockOutlined} from '@mui/icons-material';
import {styled, Typography} from '@mui/material';

import {Form} from '../../../components';
import {LoginRequest, sendPostRequest, URLs} from '../../../api';
import AppContext from '../../../context/AppContext';
import {breakpoints} from '../../../types';
import {updateProperty} from '../../../utils';

const Container = styled('div')(() => ({
  boxSizing: 'border-box',
  marginTop: '3vh',
  padding: '7% 10% 5% 10%',
  textAlign: 'center',
  width: '100%',

  [breakpoints.up_to_md.css]: {
    padding: '10%',
  },
}));

export const LoginForm = () => {
  const {dispatchError, loginCallback} = useContext(AppContext);

  const [errors, setErrors] = useState<Record<string, any>>({});
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const navigate = useNavigate();

  const handleSubmit = (data: any) => {
    const request = data as LoginRequest;

    sendPostRequest(URLs.login, request)
      .then((response) => {
        const newError = response.data.user ? false : 'Wrong email or password`';
        setErrors(updateProperty(errors, 'email', newError));
        if (errors['email'] === false) {
          loginCallback({
            ...response.data.user,
            id: parseInt(response.data.user.id, 10),
            enrolledContests: response.data.enrolledContests,
            token: response.data.token,
          });
        } else {
          forceUpdate();
        }
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.data && err.response.data.error) {
            dispatchError({errorMessage: err.response.data.error, redirectURL: '/'});
          } else {
            dispatchError({errorMessage: 'There was an error. Please try again later', redirectURL: '/'});
          }
        }
      });
  };

  return (
    <Container>
      <Typography sx={{paddingBottom: '30px'}} variant="h3">
        Log in to continue
      </Typography>
      <hr />
      <Form
        button={{
          className: 'mt-10 mb-10',
          label: 'Log in',
          onClick: handleSubmit,
        }}
        formClass="mt-25"
        fullWidth={true}
        inputClass="mb-10"
        inputs={[
          [
            {
              id: 'email',
              error: errors['email'],
              placeholder: 'Email',
              rules: {},
              startAdornment: <MailOutline />,
              type: 'input',
            },
          ],
          [
            {
              id: 'password',
              error: errors['password'],
              placeholder: 'Password',
              rules: {},
              startAdornment: <LockOutlined />,
              type: 'password-input',
            },
          ],
        ]}
      />
      {/* <Typography variant="h6_grey">
        <a className="purple-link" href="/reset-password">
          Forgot the password?
        </a>
      </Typography> */}
      <br />
      <Typography variant="h6_grey">
        Don't have an account?{' '}
        <a className="purple-link" href="/register">
          Register
        </a>
      </Typography>
      <br />
      <Typography variant="h6_grey">
        Forgot your password?{' '}
        <a className="purple-link" href="/reset-password">
          Reset Password
        </a>
      </Typography>
    </Container>
  );
};
