import {Chip, Typography} from '@mui/material';
import {ReactElement} from 'react-markdown/lib/react-markdown';

import {Difficulty, Language} from '../../types';

class Category {
  mapping: Map<string, string>;

  constructor(mapping: Map<string, string>) {
    this.mapping = mapping;
  }

  public get(name: string): string {
    if (!this.mapping.get(name)) {
      this.mapping.set(name, this.getShade());
    }
    return this.mapping.get(name)!;
  }

  public getRandomColor(): string {
    const colors = Array.from(this.mapping);
    return colors[Math.floor(Math.random() * colors.length)][1];
  }

  public getShade(): string {
    // Random color with random percentange within [-25%, 25%]
    const color = this.getRandomColor();
    const percent = Math.floor(Math.random() * 50) - 25;

    let r: number = parseInt(color.substring(1, 3), 16);
    let g: number = parseInt(color.substring(3, 5), 16);
    let b: number = parseInt(color.substring(5, 7), 16);

    r = Math.min(255, Math.floor((r * (100 + percent)) / 100));
    g = Math.min(255, Math.floor((g * (100 + percent)) / 100));
    b = Math.min(255, Math.floor((b * (100 + percent)) / 100));

    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  }
}

const colors = new Map<string, Category>([
  [
    'language',
    new Category(
      new Map<string, string>([
        [Language.JAVA.toLowerCase(), '#204CC6'],
        [Language.C.toLowerCase(), '#006CDB'],
        [Language.CPP.toLowerCase(), '#0083D9'],
        // TODO: use only option (C++/cpp) everywhere
        [Language.CPP2.toLowerCase(), '#0083D9'],
        [Language.GO.toLowerCase(), '#0095C5'],
        [Language.JAVASCRIPT.toLowerCase(), '#00A4A6'],
        [Language.PYTHON.toLowerCase(), '#00B184'],
        [Language.RUBY.toLowerCase(), '#00729D'],
      ])
    ),
  ],
  [
    'tag',
    new Category(
      new Map<string, string>([
        ['math', '#20C6B6'],
        ['introductive', '#168B87'],
      ])
    ),
  ],
  [
    'difficulty',
    new Category(
      new Map<string, string>([
        [Difficulty.EASY.toLowerCase(), '#564766'],
        [Difficulty.MEDIUM.toLowerCase(), '#915A7C'],
        [Difficulty.HARD.toLowerCase(), '#515C82'],
      ])
    ),
  ],
  [
    'role',
    new Category(
      new Map<string, string>([
        ['student', '#2c7335'],
        ['teacher', '#273d96'],
        ['author', '#663d3d'],
        ['author-teacher', '#246b60'],
        ['course-admin', '#31248c'],
      ])
    ),
  ],
  [
    'status',
    new Category(
      new Map<string, string>([
        ['active', '#248c47'],
        ['pending', '#72783e'],
        ['archived', '#2f5254'],
      ])
    ),
  ],
]);

export const toChips = (type: string, values: Array<string>) => {
  const typeColors = colors.get(type);
  return values.map((value) => (
    <Chip
      key={value}
      className="text-white mx-1"
      sx={{
        backgroundColor: typeColors?.get(value.toLowerCase()),
      }}
      label={<Typography variant="subtitle2">{value}</Typography>}
    />
  ));
};

const calculateContrastRatio = (color1: string, color2: string): number => {
  const getLuminance = (color: string): number => {
    const rgb = parseInt(color.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;

    const gammaCorrect = (c: number) => {
      const sRGB = c / 255.0;
      return sRGB <= 0.03928 ? sRGB / 12.92 : Math.pow((sRGB + 0.055) / 1.055, 2.4);
    };

    const linearR = gammaCorrect(r);
    const linearG = gammaCorrect(g);
    const linearB = gammaCorrect(b);

    return 0.2126 * linearR + 0.7152 * linearG + 0.0722 * linearB;
  };

  const luminance1 = getLuminance(color1);
  const luminance2 = getLuminance(color2);

  const lighterColor = Math.max(luminance1, luminance2);
  const darkerColor = Math.min(luminance1, luminance2);

  return (lighterColor + 0.05) / (darkerColor + 0.05);
};

export const stringsToColoredChips = (inputs: Array<string>, shortened: boolean = false): Array<ReactElement> => {
  const chips = inputs.map((input) => {
    let hash = 0;

    for (let i = 0; i < input.length; i++) {
      hash = input.charCodeAt(i) + (hash << 6) + (hash << 16) - hash;
    }
    hash = Math.abs(hash);

    const r = ((hash % 16) + 13).toString(16).padStart(2, '0');
    const g = (((hash >> 4) % 12) + 45).toString(16).padStart(2, '0');
    const b = (((hash >> 2) % 56) + 75).toString(16).padStart(2, '0');

    const hexBackgroundColor = `#${r}${g}${b}`;

    let hexComplementaryTextColor = '#000000';
    const contrastRatio = calculateContrastRatio(hexBackgroundColor, hexComplementaryTextColor);

    if (contrastRatio < 6) {
      hexComplementaryTextColor = '#FFFFFF';
    }

    return (
      <Chip
        key={input}
        sx={{
          backgroundColor: hexBackgroundColor,
          color: 'white',
        }}
        label={
          <Typography sx={{color: hexComplementaryTextColor}} variant="subtitle2">
            {input}
          </Typography>
        }
      />
    );
  });
  if (shortened && chips.length > 2) {
    const newChip = <Chip label={`+${chips.length - 2}`} sx={{background: '#133a75', color: '#FFFFFF'}} />;
    return [...chips.slice(0, 2), newChip];
  }
  return chips;
};
