import {ReactNode} from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';

import {Contact, Home, Login, Register, Problem, Problems, ResetPassword, Contest, Profile} from '../pages';
import {TrainerPage} from '../pages/module-dashboard/trainer/TrainerPage';
import {NotFound} from '../pages/not-found/NotFound';
import {Contests} from '../pages/contests/Contests';
import {SetPassword} from '../pages/auth/set-password/SetPassword';
import {ContestSubmissionView} from '../pages/contest/SubmissionView';
import {ContestDetails} from '../pages/contest/ContestDetails';
import {ContestLeaderboard} from '../pages/contest/ContestLeaderboard';
import {ContestProblems} from '../pages/contest/ContestProblems';
import ProfileHome from '../pages/profile/ProfileHome';
import ProfileAccount from '../pages/profile/profile-account/ProfileAccount';
import ProfileSettings from '../pages/profile/ProfileSettings';
import MySubmissions from '../pages/contest/MySubmissions';
import {Route as RouteData, User} from '../types';
import {ContestSubmissions} from '../components/contest-card/submissions/ContestSubmissions';
import {Statistics} from '../components/contest-card/Statistics';
import {AdminModulePage} from '../pages/module-dashboard/ModuleAdminPage';
import {StudentsManagement} from '../pages/module-dashboard/students-management/StudentsManagement';
import {GroupsManagement} from '../pages/module-dashboard/groups-management/GroupsManagement';
import {PendingUsersView} from '../pages/module-dashboard/users-management/pending-users/PendingUsersView';
import UsersManagement from '../pages/module-dashboard/users-management/UsersManagement';
import ActiveUsersView from '../pages/module-dashboard/users-management/active-users/ActiveUsersView';
import CategoriesManagement from '../pages/platform-management/categories-managament/CategoriesManagament';
import ImportUsersView from '../pages/platform-management/import-users/ImportUsersView';
import ArchivedUsersView from '../pages/module-dashboard/users-management/archived-users/ArchivedUsersView';
import {ContestTemplates} from '../pages/module-dashboard/contest-templates/ContestTemplates';
import AuthorsManagement from '../pages/platform-management/authors/AuthorsManagement';
import {ContestPreview} from '../pages/contest-preview/ContestPreview';
import {ContestPreviewProblems} from '../pages/contest-preview/ContestPreviewProblems';
import {VerifyAccount} from '../pages/auth/verify-account/VerifyAccount';
import ModulesManagement from '../pages/platform-management/modules-management/ModulesManagement';
import ProfileModules from '../pages/profile/profile-modules/ProfileModules';
import {ModuleRoleCode, Role} from '../types/entities/Role';
import PlatformManagementPage from '../pages/platform-management/PlatformManagementPage';
import PlatformUsersManagement from '../pages/platform-management/platform-users-management/PlatformUsersManagement';
import PlatformBlockedUsersView from '../pages/platform-management/platform-users-management/platform-blocked-users/PlatformBlockedUsersView';
import PlatformActiveUsersView from '../pages/platform-management/platform-users-management/platform-active-users/PlatformActiveUsersView';
import SelectedModuleSettings from '../pages/profile/SelectedModuleSettings';

export default function Router(props: {children?: Array<ReactNode>; user?: User}) {
  const commonRoutes = [new RouteData('home', '/', <Home />)];

  const signedOutRoutes = [
    new RouteData('contact', '/contact', <Contact />),
    new RouteData('login', '/login', <Login />),
    new RouteData('register', '/register', <Register />),
    new RouteData('reset-password', '/reset-password', <ResetPassword />),
    new RouteData('set-password', '/set-password', <SetPassword />),
    new RouteData('verify-account', '/verify-account', <VerifyAccount />),
  ];

  const notFoundRoutes = [
    new RouteData('404-0', '/404', <NotFound />),
    new RouteData('404-1', '/*', <Navigate replace to="/404" />),
  ];

  const allRoutes = [...commonRoutes, ...notFoundRoutes, ...(props.user?.role === undefined ? signedOutRoutes : [])];
  return (
    <>
      <Routes>
        {allRoutes.map((route: RouteData) => (
          <Route key={route.key} element={route.element} path={route.path} />
        ))}

        {props.user !== undefined && (
          <Route key="profile" element={<Profile />} path="profile">
            <Route key="profile-home" element={<ProfileHome />} index />
            <Route key="profile-account" element={<ProfileAccount />} path="account" />
            <Route key="profile-modules" element={<ProfileModules />} path="modules" />
            <Route key="profile-settings" element={<ProfileSettings />} path="settings" />
            {props.user.activeModule?.moduleId && (
              <Route key="profile-module" element={<SelectedModuleSettings />} path="module" />
            )}
          </Route>
        )}

        {props.user?.role === Role.ADMIN && (
          <>
            {props.user?.activeModule !== undefined && (
              <>
                <Route key="contest" element={<Contest />} path="/contest/:id/:groupContest">
                  <Route key="contest-problems" element={<ContestProblems />} path="problems" />
                  <Route key="contest-details" element={<ContestDetails />} path="details" />
                  <Route key="contest-leaderboard" element={<ContestLeaderboard />} path="leaderboard" />
                  <Route key="contest-statistics" element={<Statistics />} path="statistics" />
                  <Route key="contest-submissions" element={<ContestSubmissions />} path="submissions">
                    <Route key="contest-submission" element={<ContestSubmissionView />} path=":submissionId" />
                  </Route>
                  <Route key="contest-my-submissions" element={<MySubmissions />} path="my-submissions">
                    <Route key="contest-my-submission" element={<ContestSubmissionView />} path=":submissionId" />
                  </Route>
                </Route>
                <Route key="contests" element={<Contests />} path="/contests" />
                <Route
                  key="problem-contest"
                  element={<Problem />}
                  path="/problem/:problemId/:contestId/:groupContestId"
                />

                <Route key="problems" element={<Problems />} path="problems" />
                <Route key="problem" element={<Problem />} path="/problem/:problemId" />
                <Route key="contest-preview" element={<ContestPreview />} path="/contest/preview/:id">
                  <Route key="contest-preview" element={<ContestPreviewProblems />} path="problems" />
                  <Route key="contest-preview" element={<ContestDetails />} path="details" />
                </Route>
                <Route key="module-admin" element={<AdminModulePage />} path="module-admin">
                  <Route key="module-admin-users" element={<UsersManagement />} path="users">
                    <Route key="module-admin-users-active" element={<ActiveUsersView />} index />
                    <Route key="module-admin-users-pending" element={<PendingUsersView />} path="pending" />
                    <Route key="module-admin-users-archived" element={<ArchivedUsersView />} path="archived" />
                  </Route>
                  <Route key="module-admin-groups" element={<GroupsManagement />} path="groups" />
                  <Route key="module-admin-students" element={<StudentsManagement />} path="students" />
                  <Route key="module-admin-curriculum" element={<TrainerPage />} path="curriculum" />
                  <Route key="module-admin-contest-templates" element={<ContestTemplates />} path="contest-templates" />
                </Route>
              </>
            )}

            <Route key="platform" element={<PlatformManagementPage />} path="platform">
              <Route key="platform-users" element={<PlatformUsersManagement />} path="users">
                <Route key="platform-students-active" element={<PlatformActiveUsersView />} index />
                <Route key="platform-students-blocked" element={<PlatformBlockedUsersView />} path="blocked" />
              </Route>
              <Route key="platform-import-users" element={<ImportUsersView />} path="import-users" />
              <Route key="platform-modules" element={<ModulesManagement />} path="modules" />
              <Route key="platform-categories" element={<CategoriesManagement />} path="categories" />
              <Route key="platform-authors" element={<AuthorsManagement />} path="authors" />
            </Route>
          </>
        )}
        {props.user?.activeModule?.role === ModuleRoleCode.TEACHER && (
          <>
            <Route key="contest" element={<Contest />} path="/contest/:id/:groupContest">
              <Route key="contest-problems" element={<ContestProblems />} path="problems" />
              <Route key="contest-details" element={<ContestDetails />} path="details" />
              <Route key="contest-leaderboard" element={<ContestLeaderboard />} path="leaderboard" />
              <Route key="contest-statistics" element={<Statistics />} path="statistics" />
              <Route key="contest-submissions" element={<ContestSubmissions />} path="submissions">
                <Route key="contest-submission" element={<ContestSubmissionView />} path=":submissionId" />
              </Route>
            </Route>
            <Route key="contests" element={<Contests />} path="/contests" />,
            <Route key="problem-contest" element={<Problem />} path="/problem/:problemId/:contestId/:groupContestId" />
            <Route key="module-admin" element={<AdminModulePage />} path="module-admin">
              <Route key="module-admin-curriculum" element={<TrainerPage />} path="curriculum" />
              <Route key="module-admin-students" element={<StudentsManagement />} path="students" />
            </Route>
          </>
        )}
        {props.user?.activeModule?.role === ModuleRoleCode.AUTHOR && (
          <>
            <Route key="problem" element={<Problem />} path="/problem/:problemId" />

            <Route key="contest" element={<ContestPreview />} path="/contest/preview/:id">
              <Route key="contest-problems" element={<ContestPreviewProblems />} path="problems" />
              <Route key="contest-details" element={<ContestDetails />} path="details" />
            </Route>
            <Route key="module-admin" element={<AdminModulePage />} path="module-admin">
              <Route key="module-admin-contest-templates" element={<ContestTemplates />} path="contest-templates" />
            </Route>
            <Route key="problems" element={<Problems />} path="problems" />
          </>
        )}
        {props.user?.activeModule?.role === ModuleRoleCode.COURSE_ADMIN && (
          <>
            <Route key="contest" element={<Contest />} path="/contest/:id/:groupContest">
              <Route key="contest-problems" element={<ContestProblems />} path="problems" />
              <Route key="contest-details" element={<ContestDetails />} path="details" />
              <Route key="contest-leaderboard" element={<ContestLeaderboard />} path="leaderboard" />
              <Route key="contest-statistics" element={<Statistics />} path="statistics" />
              <Route key="contest-submissions" element={<ContestSubmissions />} path="submissions">
                <Route key="contest-submission" element={<ContestSubmissionView />} path=":submissionId" />
              </Route>
            </Route>
            <Route key="contests" element={<Contests />} path="/contests" />,
            <Route key="problem-contest" element={<Problem />} path="/problem/:problemId/:contestId/:groupContestId" />
            <Route key="problem" element={<Problem />} path="/problem/:problemId" />
            <Route key="contest-preview" element={<ContestPreview />} path="/contest/preview/:id">
              <Route key="contest-preview" element={<ContestPreviewProblems />} path="problems" />
              <Route key="contest-preview" element={<ContestDetails />} path="details" />
            </Route>
            <Route key="module-admin" element={<AdminModulePage />} path="module-admin">
              <Route key="module-admin-users" element={<UsersManagement />} path="users">
                <Route key="module-admin-users-active" element={<ActiveUsersView />} index />
                <Route key="module-admin-users-pending" element={<PendingUsersView />} path="pending" />
                <Route key="module-admin-users-archived" element={<ArchivedUsersView />} path="archived" />
              </Route>
              <Route key="module-admin-groups" element={<GroupsManagement />} path="groups" />
              <Route key="module-admin-students" element={<StudentsManagement />} path="students" />
              <Route key="module-admin-curriculum" element={<TrainerPage />} path="curriculum" />
              <Route key="module-admin-contest-templates" element={<ContestTemplates />} path="contest-templates" />
            </Route>
            <Route key="problems" element={<Problems />} path="problems" />
          </>
        )}
        {props.user?.activeModule?.role === ModuleRoleCode.AUTHOR_TEACHER && (
          <>
            <Route key="contest" element={<Contest />} path="/contest/:id/:groupContest">
              <Route key="contest-problems" element={<ContestProblems />} path="problems" />
              <Route key="contest-details" element={<ContestDetails />} path="details" />
              <Route key="contest-leaderboard" element={<ContestLeaderboard />} path="leaderboard" />
              <Route key="contest-statistics" element={<Statistics />} path="statistics" />
              <Route key="contest-submissions" element={<ContestSubmissions />} path="submissions">
                <Route key="contest-submission" element={<ContestSubmissionView />} path=":submissionId" />
              </Route>
            </Route>
            <Route key="contests" element={<Contests />} path="/contests" />,
            <Route key="problem-contest" element={<Problem />} path="/problem/:problemId/:contestId/:groupContestId" />
            <Route key="problem" element={<Problem />} path="/problem/:problemId" />
            <Route key="contest" element={<ContestPreview />} path="/contest/preview/:id">
              <Route key="contest-problems" element={<ContestPreviewProblems />} path="problems" />
              <Route key="contest-details" element={<ContestDetails />} path="details" />
            </Route>
            <Route key="module-admin" element={<AdminModulePage />} path="module-admin">
              <Route key="module-admin-groups" element={<GroupsManagement />} path="groups" />
              <Route key="module-admin-students" element={<StudentsManagement />} path="students" />
              <Route key="module-admin-curriculum" element={<TrainerPage />} path="curriculum" />
              <Route key="module-admin-contest-templates" element={<ContestTemplates />} path="contest-templates" />
            </Route>
            <Route key="problems" element={<Problems />} path="problems" />
          </>
        )}
        {props.user?.activeModule?.role === ModuleRoleCode.STUDENT && (
          <>
            <Route key="contest" element={<Contest />} path="/contest/:id/:groupContest">
              <Route key="contest-problems" element={<ContestProblems />} path="problems" />
              <Route key="contest-details" element={<ContestDetails />} path="details" />
              <Route key="contest-leaderboard" element={<ContestLeaderboard />} path="leaderboard" />
              <Route key="contest-my-submissions" element={<MySubmissions />} path="my-submissions">
                <Route key="contest-my-submission" element={<ContestSubmissionView />} path=":submissionId" />
              </Route>
            </Route>
            <Route key="contests" element={<Contests />} path="/contests" />,
            <Route key="problem-contest" element={<Problem />} path="/problem/:problemId/:contestId/:groupContestId" />
          </>
        )}

        {props.children}
      </Routes>
    </>
  );
}
