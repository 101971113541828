import {useContext, useState} from 'react';
import Select from 'react-select';

import {Button, TextField, Typography} from '@mui/material';
import {useTheme} from '@mui/system';
import {DesktopDatePicker} from '@mui/x-date-pickers';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';

import {sendPostRequest, URLs} from '../../api';
import {Color} from '../../types';
import {dropdownStylesConfig} from '../../components/form/dropdown/Dropdown';
import {DateTimeTextField} from '../../components/form/datetime-textfield/DateTimeTextField';
import {ModuleType} from '../../types/entities/ModuleType';
import AppContext from '../../context/AppContext';

type CreateNewGroupProps = {
  handleHttpError: (error: any) => void;
  groupAddedDispatch: () => void;
  modules: Array<ModuleType>;
};

function CreateNewGroup({handleHttpError, groupAddedDispatch, modules}: CreateNewGroupProps) {
  const {user} = useContext(AppContext);
  const theme = useTheme();

  const [groupName, setGroupName] = useState<string>('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [date, setDate] = useState(new Date(Date.now()));
  const [message, setMessage] = useState('');

  const onCreateGroupPressed = () => {
    if (user?.activeModule?.moduleId) {
      sendPostRequest(URLs.createGroup, {name: groupName, startDate: date, moduleId: user.activeModule.moduleId})
        .then((_response) => {
          setGroupName('');
          setButtonDisabled(false);
          setMessage('Group created successfully.');
          groupAddedDispatch();
        })
        .catch((err) => {
          setButtonDisabled(false);
          if (err) {
            handleHttpError(err);
          }
        });
    }
  };
  return (
    <div className="flex items-center w-full justify-between">
      <div className="flex items-center gap-2">
        <TextField
          onChange={(event) => {
            setGroupName(event.target.value);
          }}
          id="filled-group-name-input"
          label="Group Name"
          variant="filled"
          value={groupName}
          sx={{
            width: '75%',
            backgroundColor: theme.palette.inputBox.backgroundColor,
            input: {color: 'white'},
          }}
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Start Date"
            inputFormat="MM/dd/yyyy"
            value={date}
            onChange={(date) => {
              if (date) {
                setDate(date);
              }
            }}
            renderInput={(params) => <DateTimeTextField {...params} />}
          />
        </LocalizationProvider>

        <Button
          disabled={buttonDisabled || groupName === ''}
          color={Color.SECONDARY}
          variant="contained"
          size="small"
          onClick={() => onCreateGroupPressed()}
        >
          Create group
        </Button>
      </div>
      {message !== '' && (
        <Typography className="align-middle" color={theme.palette.primary.light} variant="body1">
          {message}
        </Typography>
      )}
    </div>
  );
}

export default CreateNewGroup;
