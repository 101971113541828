import {NavLink, Outlet, useOutletContext} from 'react-router-dom';

import {Typography} from '@mui/material';

export type PlatformUsersManagementOutletProps = {
  handleHttpError: (err: any) => void;
};

function PlatformUsersManagement() {
  const {handleHttpError}: PlatformUsersManagementOutletProps = useOutletContext();

  const UsersManagementTabs: Array<{name: string; path: string}> = [
    {
      name: 'ACTIVE USERS',
      path: '',
    },
    {
      name: 'BLOCKED USERS',
      path: 'blocked',
    },
  ];

  return (
    <aside className="flex flex-col">
      <nav className="flex gap-8 justify-around">
        {UsersManagementTabs.map((tab) => (
          <NavLink key={tab.name} to={tab.path} replace={true} className="no-underline" end>
            {({isActive}) => (
              <Typography style={{color: isActive ? 'inherit' : '#808080'}} variant="h7">
                {tab.name}
              </Typography>
            )}
          </NavLink>
        ))}
      </nav>
      <div className="py-4 w-full">
        <Outlet context={{handleHttpError}} />
      </div>
    </aside>
  );
}

export default PlatformUsersManagement;
