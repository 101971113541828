import {ModuleRoleCode} from '../entities/Role';

export const defaultPageRedirect = (moduleRole: ModuleRoleCode | null) => {
  switch (moduleRole) {
    case ModuleRoleCode.AUTHOR:
      return '/problems';
    case null:
      return '/profile';
    default:
      return '/contests';
  }
};
