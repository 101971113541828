import {ReactNode, useContext, useEffect, useState} from 'react';
import {NavLink, Outlet} from 'react-router-dom';

import {Typography} from '@mui/material';
import {PersonPin, Settings, AccountBox, Logout, Extension, ViewModule} from '@mui/icons-material';

import AppContext from '../../context/AppContext';
import {Page} from '../../components';
import {ModuleEntry} from '../../types/entities/ModuleType';
import {request, URLs} from '../../api';

export type ProfileOutletProps = {
  handleHttpError: (error: any) => void;
};

export const Profile = () => {
  const [myModules, setMyModules] = useState<Array<ModuleEntry>>([]);
  const {user, dispatchError, logoutCallback} = useContext(AppContext);

  useEffect(() => {
    request<{}, {}, Array<ModuleEntry>>(URLs.getMyModules, {
      method: 'GET',
      successCallback: (response) => {
        setMyModules(response);
      },
      errorCallback(error) {
        handleHttpError(error);
      },
    });
  }, []);

  const handleHttpError = (err: any) => {
    if (err.response && err.response.data && err.response.data.error) {
      dispatchError({errorMessage: err.response.data.error, redirectURL: '/contests'});
    } else {
      dispatchError({errorMessage: 'There was an error. Please try again later', redirectURL: '/contests'});
    }
  };

  return (
    <Page>
      <div className="flex items-center justify-center w-full">
        <div className="grid grid-cols-12 gap-5 rounded-2xl w-full">
          <aside className="col-span-3 bg-card-main rounded-2xl h-[80vh] sticky top-[96px]">
            <nav className="flex flex-col justify-between h-full px-6 py-4">
              <div>
                {[...profileTabs(myModules.find((m) => m.moduleId == user?.activeModule?.moduleId))].map((tab) => (
                  <NavLink
                    key={`${tab.name}`}
                    to={tab.path}
                    replace={true}
                    end
                    className="flex items-center rounded-lg h-12 px-4 mb-2 no-underline"
                    style={({isActive}) => ({
                      backgroundColor: isActive ? '#16a137' : 'inherit',
                    })}
                  >
                    {tab.icon}
                    <Typography variant="body1">{tab.name}</Typography>
                  </NavLink>
                ))}
              </div>
              <button onClick={logoutCallback} className="flex items-center rounded-lg w-full h-12 px-4 mb-2">
                <Logout fontSize="large" className="text-white mr-2" />
                <span>Log out</span>
              </button>
            </nav>
          </aside>

          <div className="col-span-9 bg-card-main rounded-2xl w-full overflow-y-scroll">
            <div className="px-6 py-4 w-full">
              <Outlet context={{handleHttpError}} />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

type ProfileTab = {name: string; path: string; icon: ReactNode};

const profileTabs = (selectedModule: ModuleEntry | undefined): Array<ProfileTab> => {
  const tabs = [
    {
      name: 'Profile',
      path: '',
      icon: <AccountBox fontSize="large" className="text-white mr-2" />,
    },
    {name: 'My account', path: 'account', icon: <PersonPin fontSize="large" className="text-white mr-2" />},
    {
      name: 'Modules',
      path: 'modules',
      icon: <ViewModule fontSize="large" className="text-white mr-2" />,
    },
    {
      name: 'Settings',
      path: 'settings',
      icon: <Settings fontSize="large" className="text-white mr-2" />,
    },
  ];

  if (selectedModule && selectedModule.status == 'active') {
    tabs.push({
      name: selectedModule.moduleName,
      path: 'module',
      icon: <Extension fontSize="large" className="text-white mr-2" />,
    });
  }

  return tabs;
};
