import {useContext, useEffect, useState} from 'react';
import {useOutletContext} from 'react-router-dom';

import {request, sendPutRequest, URLs} from '../../api';
import FilterActiveGroupsSelect from '../../components/user/FilterActiveGroupsSelect';
import LeaderboardVisibilityToggle from '../../components/user/LeaderboardVisibilityToggle';
import {ModuleEntry, ModuleMemberSettings} from '../../types/entities/ModuleType';
import {ModuleRoleCode} from '../../types/entities/Role';
import {ProfileOutletProps} from './Profile';
import AppContext from '../../context/AppContext';

function SelectedModuleSettings() {
  const {handleHttpError}: ProfileOutletProps = useOutletContext();

  const {user} = useContext(AppContext);

  const [moduleSpecificSettings, setModuleSpecificSettings] = useState<ModuleMemberSettings | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [module, setModule] = useState<ModuleEntry | undefined>(undefined);

  const onModuleSettingsSave = (partialModuleMemberSettings: ModuleMemberSettings) =>
    sendPutRequest(URLs.userModuleSettings(user!.id, module!.moduleId), {
      settings: partialModuleMemberSettings,
    })
      .then(() => true)
      .catch((err) => {
        handleHttpError(err);
        return false;
      });

  useEffect(() => {
    setIsLoading(true);
    request<{}, {}, Array<ModuleEntry>>(URLs.getMyModules, {
      method: 'GET',
      successCallback: (response) => {
        const module = response.find((m) => m.moduleId === user?.activeModule?.moduleId);

        setModule(module);
        request<{}, {}, {settings: ModuleMemberSettings}>(URLs.userModuleSettings(user!.id, module!.moduleId), {
          method: 'GET',
          successCallback(response) {
            setModuleSpecificSettings(response.settings);
          },
          errorCallback(error) {
            handleHttpError(error);
          },
        });
      },
      errorCallback(error) {
        handleHttpError(error);
      },
    });

    setIsLoading(false);
  }, []);

  return (
    <>
      {module && (
        <div className="text-4xl text-center mb-6 px-12">
          Managing module <b>{module.moduleName}</b>
        </div>
      )}
      {!isLoading && module && moduleSpecificSettings && (
        <div className="flex flex-col gap-4">
          {module.role === ModuleRoleCode.AUTHOR && (
            <div className="text-gray-100 text-lg">
              🎉 Horray! You do not have any module specific settings to set-up! Note that the settings can change based
              on your role within the module.
            </div>
          )}

          {module.role !== ModuleRoleCode.AUTHOR && (
            <div className="px-4">
              <div className="text-white font-semibold text-2xl mb-1">Filter active groups</div>
              <div className="text-gray-100">
                Customize the groups you want to view by applying filters. If no specific groups are selected, all
                groups will be shown by default.
              </div>
              <FilterActiveGroupsSelect
                filteredGroupsIdsList={moduleSpecificSettings.activeGroups ?? []}
                module={module}
                saveCallback={onModuleSettingsSave}
                handleHttpError={(errorMessage) => handleHttpError(errorMessage)}
              />
            </div>
          )}
          {(module.role === ModuleRoleCode.TEACHER || module.role === ModuleRoleCode.AUTHOR_TEACHER) && (
            <div className="px-4">
              <div className="text-white font-semibold text-2xl mb-1">Hidden in leaderboard</div>
              <div className="text-gray-100">As a teacher you can choose to appear or not in the leaderboard.</div>
              <LeaderboardVisibilityToggle
                currentIsCheckedSetting={moduleSpecificSettings.appearInLeaderboard ?? false}
                saveCallback={onModuleSettingsSave}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default SelectedModuleSettings;
