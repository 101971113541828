import {useContext, useEffect, useMemo, useState} from 'react';
import {useOutletContext} from 'react-router-dom';
import Select from 'react-select';

import {Button} from '@mui/material';

import {Group} from '../../../types/entities/Group';
import {sendGetRequest, URLs} from '../../../api';
import {GroupUsers} from './GroupUsers';
import {dropdownStylesConfig} from '../../../components/form/dropdown/Dropdown';
import {ModuleAdminPageOutletProps} from '../ModuleAdminPage';
import AppContext from '../../../context/AppContext';
import AssignUsersToGroupBulkModal from './AssignUsersToGroupBulkModal';

export const StudentsManagement = () => {
  const {user} = useContext(AppContext);
  const {handleHttpError}: ModuleAdminPageOutletProps = useOutletContext();

  const [groups, setGroups] = useState<Array<Group>>([]);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [refreshSelectedGroup, setRefreshSelectedGroup] = useState(false);

  const [showAssignUsersModal, setShowAssignUsersModal] = useState<boolean>(false);

  useEffect(() => {
    sendGetRequest(URLs.accessibleUserModuleGroups(user!.activeModule!.moduleId, true))
      .then((response) => {
        setGroups(response.data);

        if (response.data.length === 1) {
          setSelectedGroup(response.data[0]);
        }
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  }, [user]);

  const groupOptions = useMemo(
    () => (groups ? groups.map((group) => ({value: group.id, label: group.name})) : []),
    [groups]
  );

  const dropdownStyles = dropdownStylesConfig();

  return (
    <>
      <div className="flex flex-row justify-between items-center w-full">
        <div className="w-96">
          <Select
            id="select-group"
            placeholder="Select group..."
            defaultValue={selectedGroup?.name}
            onChange={(event) => {
              const selectedId = (event as {value: string; label: string}).value;
              const group = groups.filter((gr) => gr.id === Number(selectedId))[0];
              setSelectedGroup(group);
            }}
            value={selectedGroup ? {value: selectedGroup.id, label: selectedGroup.name} : null}
            options={groupOptions}
            styles={dropdownStyles}
            isDisabled={groupOptions.length === 1}
          />
        </div>
        {groups && groups.length > 0 && (
          <div>
            {selectedGroup && (
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={() => {
                  setShowAssignUsersModal(true);
                }}
              >
                Add New Users
              </Button>
            )}
          </div>
        )}
      </div>
      {selectedGroup && (
        <GroupUsers
          selectedGroup={selectedGroup}
          refreshSelectedGroup={refreshSelectedGroup}
          handleHttpError={handleHttpError}
        />
      )}
      {selectedGroup && showAssignUsersModal && (
        <AssignUsersToGroupBulkModal
          selectedGroup={selectedGroup}
          onClose={() => {
            setShowAssignUsersModal(false);
            setRefreshSelectedGroup(!refreshSelectedGroup);
          }}
        />
      )}
    </>
  );
};
