import {useContext, useReducer, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {LockOutlined, Password, Lock} from '@mui/icons-material';

import AppContext from '../../../../context/AppContext';
import {Form} from '../../../../components';
import {ChangePasswordRequest} from '../../../../api/Requests';
import {updateProperty} from '../../../../utils';
import {request, URLs} from '../../../../api';

type ChangePasswordFormProps = {
  dispachSnackbarMessage: (message: string) => void;
};

function ChangePasswordForm({dispachSnackbarMessage}: ChangePasswordFormProps) {
  const context = useContext(AppContext);
  const user = context.user;
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const navigate = useNavigate();

  const [errors, setErrors] = useState<Record<string, any>>({});

  const [formKey, setFormKey] = useState(0);

  const resetForm = () => {
    setFormKey((prevKey) => prevKey + 1);
  };

  const handleSubmit = (data: ChangePasswordRequest) => {
    if (user != null) {
      if (data['newPassword'] != data['newPasswordRepeat']) {
        setErrors(updateProperty(errors, 'newPasswordRepeat', 'New password and confirm password do not match'));
        forceUpdate();
        return;
      }

      request<never, ChangePasswordRequest, never>(URLs.changeUserPassword(user.id), {
        method: 'POST',
        body: {
          currentPassword: data.currentPassword,
          newPassword: data.newPassword,
          newPasswordRepeat: data.newPasswordRepeat,
        },
        successCallback: () => {
          dispachSnackbarMessage('Password updated successfully');
          resetForm();
        },
        errorCallback: (error: any) => {
          context.dispatchError({
            errorMessage: error?.response?.data?.message ?? 'There was an error. Please try again later',
          });
        },
      });
    }
  };

  return (
    <>
      {user != null && (
        <>
          <Form
            key={formKey}
            button={{
              className: 'mt-25',
              label: 'Change password',
              onClick: handleSubmit,
            }}
            fullWidth={true}
            inputs={[
              [
                {
                  id: 'currentPassword',
                  error: errors['currentPassword'],
                  placeholder: 'Current password',
                  rules: {},
                  startAdornment: <Password />,
                  type: 'password-input',
                },
              ],
              [
                {
                  id: 'newPassword',
                  error: errors['newPassword'],
                  placeholder: 'New password',
                  rules: {
                    length: [
                      {
                        cond: 'between',
                        low: 6,
                        high: 30,
                        message: 'Password must be between 6 and 30 characters',
                      },
                    ],
                    pattern: [
                      {
                        message: 'Password must not contain white spaces',
                        type: 'noWhiteSpaces',
                      },
                    ],
                  },
                  startAdornment: <LockOutlined />,
                  type: 'password-input',
                  autoComplete: 'newPassword',
                },
              ],
              [
                {
                  id: 'newPasswordRepeat',
                  error: errors['newPasswordRepeat'],
                  placeholder: 'Confirm new password',
                  rules: {
                    length: [
                      {
                        cond: 'not-empty',
                        message: 'You need to confirm your new password',
                      },
                    ],
                  },
                  startAdornment: <Lock />,
                  type: 'password-input',
                  autoComplete: 'newPassword',
                },
              ],
            ]}
          />
        </>
      )}
    </>
  );
}

export default ChangePasswordForm;
