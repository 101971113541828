import {useState} from 'react';
import Select from 'react-select';

import {Close, DoneAll} from '@mui/icons-material';
import {Alert, Button, IconButton, Modal, Typography} from '@mui/material';

import {dropdownStylesConfig} from '../../../../components/form/dropdown/Dropdown';
import {ActiveUserRecord} from '../../../../api/responses/PlatformUsersResponseTypes';
import {Group} from '../../../../types/entities/Group';
import {UpdateUserParams} from './types';
import {fromRoleCodeToModuleString, ModuleRole, ModuleRoleCode} from '../../../../types/entities/Role';

type EditActiveUserModalProps = {
  user: ActiveUserRecord;
  allGroups: Array<Group>;
  closeCallback: () => void;
  updateUserCallback: (updatedUser: UpdateUserParams) => void;
};

function EditActiveUserModal({user, allGroups, closeCallback, updateUserCallback}: EditActiveUserModalProps) {
  const [initialActiveUser, _] = useState<UpdateUserParams>({...user});
  const [editActiveUser, setEditActiveUser] = useState<UpdateUserParams>({...user});

  const dropdownStyles = dropdownStylesConfig();

  {
    /* TODO: REFACTOR FORM AFTER WE CHANGE OUR FORM COMPONENT */
  }
  return (
    <Modal open={true} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div className="top-2/4 left-2/4 w-[40%] h-fit p-4 absolute translate-x-[-50%] translate-y-[-50%] bg-background-default">
        <div className="absolute right-1 px-4">
          <IconButton onClick={closeCallback}>
            <Close color="success" sx={{color: 'white'}} />
          </IconButton>
        </div>
        <div className="flex flex-col justify-center items-center gap-3 p-2">
          <Typography variant="h2">Edit user</Typography>
          <div className="flex flex-col items-start w-full p-4 gap-2">
            <div className="w-full">
              <Typography variant="body1">Email</Typography>
              <input
                className="w-full p-4 h-14 rounded outline-none text-white border-none bg-inputBox-backgroundColor disabled:text-gray-500 disabled:cursor-not-allowed"
                placeholder="Email"
                value={editActiveUser.email}
                disabled
              />
            </div>
            <div className="w-full">
              <Typography variant="body1">First Name</Typography>
              <input
                className="w-full p-[15px] h-14 rounded outline-none text-white border-none bg-inputBox-backgroundColor"
                placeholder="First name"
                value={editActiveUser.firstName}
                onChange={(event) => setEditActiveUser({...editActiveUser, firstName: event.target.value})}
              />
            </div>
            <div className="w-full">
              <Typography variant="body1">Last Name</Typography>
              <input
                className="w-full p-[15px] h-14 rounded outline-none text-white border-none bg-inputBox-backgroundColor"
                placeholder="Last name"
                value={editActiveUser.lastName}
                onChange={(event) => setEditActiveUser({...editActiveUser, lastName: event.target.value})}
              />
            </div>
            <div className="w-full">
              <Typography variant="body1">Nickname</Typography>
              <input
                className="w-full p-[15px] h-14 rounded outline-none text-white border-none bg-inputBox-backgroundColor"
                placeholder="Nickname"
                value={editActiveUser.nickName}
                onChange={(event) => setEditActiveUser({...editActiveUser, nickName: event.target.value})}
              />
            </div>
            <div className="w-full">
              <Typography variant="body1">Role</Typography>
              <Select
                styles={dropdownStyles}
                value={{
                  value: editActiveUser.role,
                  label: fromRoleCodeToModuleString(editActiveUser.role).replace(/_/g, ' '),
                }}
                options={(Object.keys(ModuleRole) as Array<keyof typeof ModuleRole>).map((key: string) => ({
                  value: ModuleRoleCode[key as keyof typeof ModuleRole],
                  label: key.replace(/_/g, ' '),
                }))}
                onChange={(event) => {
                  const role: number = (event as {value: number; label: string}).value;
                  setEditActiveUser({...editActiveUser, role: role});
                }}
                isSearchable={false}
                menuPlacement="top"
              />
            </div>
            <div className="w-full">
              <Typography variant="body1">Groups</Typography>
              <Select
                styles={dropdownStyles}
                options={allGroups.map((group) => ({value: group.id, label: group.name}))}
                value={allGroups
                  .filter((group) => editActiveUser.groupsIds.includes(group.id))
                  .map((group) => ({value: group.id, label: group.name}))}
                onChange={(event) => {
                  const groupsIds: Array<number> = (event as Array<{value: number | null; label: string}>).map(
                    (group) => group.value
                  ) as Array<number>;
                  setEditActiveUser({...editActiveUser, groupsIds: groupsIds});
                }}
                closeMenuOnSelect={false}
                menuPlacement="top"
                isMulti
              />
            </div>
            {(initialActiveUser.role === ModuleRoleCode.AUTHOR ||
              initialActiveUser.role === ModuleRoleCode.AUTHOR_TEACHER) &&
              editActiveUser.role !== ModuleRoleCode.AUTHOR &&
              editActiveUser.role !== ModuleRoleCode.AUTHOR_TEACHER && (
                <Alert severity="warning">
                  You are about to convert an Author or Author-Teacher to an non-author role, this action will result in
                  the member losing it's write accesses to Problems and Contests, keep that in mind.
                </Alert>
              )}
            {initialActiveUser.role !== ModuleRoleCode.COURSE_ADMIN &&
              editActiveUser.role === ModuleRoleCode.COURSE_ADMIN && (
                <Alert severity="warning">
                  A new Course-Admin will have the same rights as you, and can, for example, delete groups, add new
                  Course-Admins and remove users (including you). Be careful who you assign this role to.
                </Alert>
              )}
            <div className="flex flex-row justify-end gap-4 mt-6 w-full">
              <Button
                variant="contained"
                color="primary"
                className="mr-4"
                startIcon={<DoneAll />}
                onClick={() => updateUserCallback(editActiveUser)}
              >
                Save
              </Button>

              <Button variant="contained" color="gridSecondary" startIcon={<Close />} onClick={() => closeCallback()}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EditActiveUserModal;
