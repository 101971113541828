import {useContext, useEffect, useReducer, useState} from 'react';
import {useOutletContext} from 'react-router-dom';
import Select from 'react-select';

import {Button, CircularProgress, Modal, Tooltip, Typography} from '@mui/material';

import {ModuleEntry, ModuleType} from '../../../types/entities/ModuleType';
import {request, URLs} from '../../../api';
import {ProfileOutletProps} from '../Profile';
import ProfileModulesTables from './ProfileModulesTable';
import {Role} from '../../../types';
import AppContext from '../../../context/AppContext';
import {dropdownStylesConfig} from '../../../components/form/dropdown/Dropdown';
import LeaveModuleModal from './LeaveModuleModal';

function ProfileModules() {
  const dropdownStyles = dropdownStylesConfig();
  const {user} = useContext(AppContext);

  const [update, forceUpdate] = useReducer((x) => x + 1, 0);

  const {handleHttpError}: ProfileOutletProps = useOutletContext();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [myModules, setMyModules] = useState<Array<ModuleEntry>>([]);

  const [allListedModules, setAllListedModules] = useState<Array<ModuleType>>([]);
  const [selectedListedModuleIdToRequest, setSelectedListedModuleIdToRequest] = useState<number | null>(null);

  const [showRequestAccessToNewModal, setShowRequestAccessToNewModal] = useState<boolean>(false);
  const [moduleIdManaging, setModuleIdManaging] = useState<number | null>(null);

  useEffect(() => {
    request<{}, {}, Array<ModuleEntry>>(URLs.getMyModules, {
      method: 'GET',
      successCallback: (response) => {
        setIsLoading(false);
        setMyModules(response);
      },
      errorCallback(error) {
        handleHttpError(error);
      },
    });

    request<{}, {}, Array<ModuleType>>(URLs.getListedModules, {
      method: 'GET',
      successCallback: (response) => {
        setIsLoading(false);
        setAllListedModules(response);
      },
      errorCallback(error) {
        handleHttpError(error);
      },
    });
  }, [update]);

  const sendJoinRequestToListedModule = (moduleId: number) => {
    request<{}, {}, {}>(URLs.moduleRequestJoin(moduleId), {
      method: 'POST',
      successCallback: () => {
        setIsLoading(true);
        forceUpdate();
      },
      errorCallback(error) {
        handleHttpError(error);
      },
    });

    setSelectedListedModuleIdToRequest(null);
    setShowRequestAccessToNewModal(false);
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-1">
        <div className="text-white font-bold text-4xl">Modules</div>
        {/* <div className="text-gray-100 ">Desctiption</div> */}
      </div>
      <div className="flex flex-row justify-between items-center rounded-2xl bg-card-dark px-7 py-4 gap-6">
        <div>
          <div className="text-white font-semibold text-3xl mb-1">Join a new module</div>
          <div className="text-gray-100">
            You can request to join one of the publicly listed modules. Once a course admin approves your request, you
            will gain access to the course&apos;s groups
          </div>
        </div>
        <Tooltip title={user?.role === Role.ADMIN ? 'Platform-Admins cannot join nor leave modules' : ''}>
          <span>
            <Button
              disabled={user?.role === Role.ADMIN}
              variant="contained"
              onClick={() => setShowRequestAccessToNewModal(true)}
            >
              Request access
            </Button>
          </span>
        </Tooltip>
      </div>
      <div className="flex flex-col gap-2 rounded-2xl bg-card-dark px-7 py-4">
        <div>
          <div className="text-white font-semibold text-3xl mb-1">Manage modules</div>
          <div className="text-gray-100">
            In this section, you can view and manage the modules you are currently enrolled in.
          </div>
        </div>
        {!isLoading ? (
          <ProfileModulesTables modules={myModules} onUnassignRequest={(moduleId) => setModuleIdManaging(moduleId)} />
        ) : (
          <div className="flex flex-row justify-center">
            <CircularProgress color="inherit" />
          </div>
        )}
      </div>
      {moduleIdManaging && (
        <LeaveModuleModal
          module={myModules.find((m) => m.moduleId == moduleIdManaging)!}
          onClose={() => {
            setModuleIdManaging(null);
          }}
          leftModuleCallback={() => forceUpdate()}
        />
      )}
      {showRequestAccessToNewModal && (
        <Modal open={showRequestAccessToNewModal} onClose={() => setShowRequestAccessToNewModal(false)}>
          <div className="flex flex-col items-center text-center gap-3 bg-background-default top-2/4 left-2/4 w-[35%] h-fit px-4 py-5 absolute translate-x-[-50%] translate-y-[-50%] ">
            <Typography variant="h4">Request to join a module</Typography>
            <Typography variant="subtitle2">
              Once the request is sent an course-admin will review it and decide if to accept it or reject it. Note that
              the following list includes only modules to which you are not yet a member.
            </Typography>
            <Select
              id="select-module"
              placeholder="Select module..."
              onChange={(event) => {
                const selectedId = (event as {value: number; label: string}).value;
                setSelectedListedModuleIdToRequest(selectedId);
              }}
              options={allListedModules
                .filter((alm) => myModules.every((am) => am.moduleId != alm.moduleId))
                .map((module) => ({label: module.moduleName, value: module.moduleId}))}
              styles={dropdownStyles}
            />
            <div className="flex flex-row gap-5 mt-2 items-center justify-center">
              <Button
                variant="contained"
                color="warning"
                disabled={!selectedListedModuleIdToRequest}
                onClick={() =>
                  selectedListedModuleIdToRequest && sendJoinRequestToListedModule(selectedListedModuleIdToRequest)
                }
              >
                Send request
              </Button>
              <Button variant="contained" color="gridPrimary" onClick={() => setShowRequestAccessToNewModal(false)}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default ProfileModules;
