import * as React from 'react';
import {useContext, useReducer, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import {AdminPanelSettings, Edit, Visibility} from '@mui/icons-material';
import {useTheme} from '@mui/system';

import AppContext from '../../../context/AppContext';
import {ContestTemplatesViewResponse} from '../../../api/responses/ContestsViewResponseTypes';
import {EnrollmentStatus, Role} from '../../../types';
import {ContestTemplateEnhancedTableHead} from './ContestTemplatesTableHeader';
import {getComparator, stableSort} from '../../../utils/tables/TableSorting';
import {contestsPaginationStyle, contestsTableStyles} from '../../contests/contests-table/contest-table-styles';
import {ModuleRoleCode, RoleCode} from '../../../types/entities/Role';

export interface Data {
  title: string;
  module: string;
  action: any;
}

export type ContestsTableProps = {
  templates: ContestTemplatesViewResponse['contests'];
  modules: {[key: number]: any};
  onTemplateEdit: (templateId: number) => void;
  onTemplateManageAuthors: (templateId: number) => void;
  onTemplateView: (templateId: number) => void;
};

export type Order = 'asc' | 'desc';

export const ContestsTemplatesTable = ({
  templates,
  modules,
  onTemplateEdit,
  onTemplateManageAuthors,
  onTemplateView,
}: ContestsTableProps) => {
  const params = new URLSearchParams(location.search);

  const [order, setOrder] = React.useState<Order>((params.get('order') as Order) || 'desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>((params.get('orderby') as keyof Data) || 'start_date');
  const [page, setPage] = React.useState(0);
  const [dense, _setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const context = useContext(AppContext);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const navigate = useNavigate();

  const theme = useTheme();

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    navigate(`/module-admin/contest-templates?orderby=${property}&order=${isAsc ? 'desc' : 'asc'}`, {replace: true});
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - templates.length) : 0;

  useEffect(() => {
    setPage(0);
  }, []);

  return (
    <div className="w-full py-2">
      <TableContainer component={Paper}>
        <Table sx={contestsTableStyles(theme)} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
          <ContestTemplateEnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={templates.length}
          />
          <TableBody>
            {stableSort(templates, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((template: ContestTemplatesViewResponse['contests'][0], index) => (
                <TableRow hover tabIndex={-1} key={`${template.title}-${index}`} id={`template-row-${index}`}>
                  <TableCell>{template.title}</TableCell>
                  {/* <TableCell>{modules[template.module_id]}</TableCell> */}
                  <TableCell>
                    <Tooltip title="Edit contest template">
                      <IconButton
                        color="inherit"
                        disabled={
                          context.user?.role !== Role.ADMIN &&
                          context.user!.activeModule!.role !== ModuleRoleCode.COURSE_ADMIN &&
                          (context.user!.activeModule!.role !== ModuleRoleCode.AUTHOR ||
                            !template.authors_ids.includes(Number(context.user!.id))) &&
                          (context.user!.activeModule!.role !== ModuleRoleCode.AUTHOR_TEACHER ||
                            !template.authors_ids.includes(Number(context.user!.id)))
                        }
                        onClick={() => {
                          onTemplateEdit(template.id);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip color="inherit" title="Manage authors">
                      <IconButton
                        disabled={
                          context.user?.role !== Role.ADMIN &&
                          context.user!.activeModule!.role !== ModuleRoleCode.COURSE_ADMIN &&
                          (context.user!.activeModule!.role !== ModuleRoleCode.AUTHOR ||
                            !template.authors_ids.includes(Number(context.user!.id))) &&
                          (context.user!.activeModule!.role !== ModuleRoleCode.AUTHOR_TEACHER ||
                            !template.authors_ids.includes(Number(context.user!.id)))
                        }
                        onClick={() => onTemplateManageAuthors(template.id)}
                      >
                        <AdminPanelSettings />
                      </IconButton>
                    </Tooltip>
                    <Tooltip color="inherit" title="View contest template">
                      <IconButton onClick={() => onTemplateView(template.id)}>
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 43) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={contestsPaginationStyle(theme)}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={templates.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};
