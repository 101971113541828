import {Archive} from '@mui/icons-material';
import {Button, Tooltip} from '@mui/material';
import JSZip from 'jszip';

import {sendGetRequest, URLs} from '../../../api';
import {ContestSubmission} from '../../../types/entities/ContestSubmission';
import {Problem} from '../../../types';
import {ContestViewData} from '../../../api/responses/ContestViewResponseTypes';
import {formatYYYYMMDDHHMMSS, fromDateToSmall} from '../../../utils/date-utils';

type SubmissionsArchiveButtonProps = {
  contest: ContestViewData;
  groupContest: number;
  searchParams: {
    problemId?: number;
    userId?: number;
    total?: number;
    latest?: boolean;
    duringContest?: boolean;
  };
};

function SubmissionsArchivedExportButton({contest, groupContest, searchParams}: SubmissionsArchiveButtonProps) {
  const archiveSubmissions = async () => {
    const allSearchParams = new URLSearchParams();

    let problems: {[id: number]: Problem} | null = null;
    let submissions: Array<ContestSubmission> = [];

    Object.entries({
      ...searchParams,
      limit: searchParams.total,
    }).forEach(([key, value]) => {
      if (value != null) {
        allSearchParams.append(key, value.toString());
      }
    });

    await sendGetRequest(
      `${URLs.getContestSubmissions(Number(contest.id))}?groupContestId=${groupContest}&${allSearchParams.toString()}`
    ).then((response) => {
      submissions = response.data.submissions;
      problems = response.data.problems;
    });

    const zip = new JSZip();

    submissions.forEach((submission) => {
      const problem = problems ? problems[submission.problemId] : null;
      const problemName = problem ? problem?.title : 'unknown';

      let fileExtension;

      switch (submission.languageId) {
        case 1:
          fileExtension = 'java';
          break;
        case 2:
          fileExtension = 'c';
          break;
        case 3:
          fileExtension = 'cpp';
          break;
        case 4:
          fileExtension = 'py';
          break;
        default:
          fileExtension = 'txt';
      }

      const fileName =
        `${submission.userEmail.split('@')[0]}-${problemName.replaceAll(' ', '_')}-${formatYYYYMMDDHHMMSS(
          new Date(submission.date)
        )}.` + fileExtension;

      zip.file(fileName, submission.code);
    });

    zip
      .generateAsync({type: 'blob'})
      .then(function (blob: Blob | MediaSource) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${contest.title}-${fromDateToSmall(new Date())}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      })
      .catch(function (error: Error) {
        console.error('Error generating zip file:', error);
      });
  };

  return (
    <>
      <Tooltip title="Exports achvied files containing the submitted code based on the selected filters">
        <Button
          startIcon={<Archive />}
          size="small"
          color="primary"
          variant="contained"
          onClick={() => archiveSubmissions()}
        >
          Export to Code Archive
        </Button>
      </Tooltip>
    </>
  );
}

export default SubmissionsArchivedExportButton;
