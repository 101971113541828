import Select from 'react-select';

import {Typography} from '@mui/material';

import {Category} from '../../types/entities/Category';
import {dropdownStylesConfig} from '../../components/form/dropdown/Dropdown';
import {Difficulty} from '../../types';
import {ProblemsFilterState} from '../../pages/problems/ProblemsPageV2';
import {languageOptions as languageOps} from '../../types/entities/Language';

type ProblemPageFilters = {
  difficultyDispatch: (value: Difficulty | null) => void;
  languageIdDispatch: (value: number | null) => void;
  categoryDispatch: (value: number | null) => void;
  allCategories: Array<Category>;
  state: ProblemsFilterState;
};

export default function ProblemPageFilters({
  difficultyDispatch,
  languageIdDispatch,
  categoryDispatch,
  allCategories,
  state,
}: ProblemPageFilters) {
  const dropdownStyles = dropdownStylesConfig();

  const difficultyOptions = [
    {value: null, label: 'All'},
    {value: Difficulty.EASY, label: 'Easy'},
    {value: Difficulty.MEDIUM, label: 'Medium'},
    {value: Difficulty.HARD, label: 'Hard'},
  ];

  const languageOptions = [
    {value: null, label: 'All'},
    ...languageOps.map((language) => ({value: language.languageId, label: language.label})),
  ];

  const categoryOptions = [
    {
      label: 'Misc',
      options: [
        {value: null, label: 'All'},
        {value: -1, label: 'Uncategorized'},
      ],
    },
    {
      label: 'Categoires',
      options: [...allCategories.map((category) => ({value: category.id, label: category.title}))],
    },
  ];

  return (
    <>
      <div className="bg-card-main rounded-2xl p-4 mt-3">
        <div className="flex flex-col justify-start w-full gap-4">
          <div className="flex flex-col gap-2">
            <Typography variant="h7">Difficulty</Typography>
            <Select
              styles={dropdownStyles}
              options={difficultyOptions}
              defaultValue={difficultyOptions.find((option) => option.value === state.difficulty)}
              onChange={(event) => {
                const difficulty = (event as {value: Difficulty | null; label: string}).value;
                difficultyDispatch(difficulty);
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Typography variant="h7">Category</Typography>
            <Select
              styles={dropdownStyles}
              options={categoryOptions}
              defaultValue={categoryOptions.flatMap((option) => option.options).find((c) => c.value === state.category)}
              onChange={(event) => {
                categoryDispatch((event as {value: number | null; label: string}).value);
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Typography variant="h7">Language</Typography>
            <Select
              styles={dropdownStyles}
              options={languageOptions}
              defaultValue={languageOptions.find((option) => option.value === state.languageId)}
              onChange={(event) => {
                const language = (event as {value: number | null; label: string}).value;
                languageIdDispatch(language);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
